import React, { useState } from "react";
import PreviewFileModal from "./Filemanager_PreviewFileModal";
import { recover } from "../../../utils/icons";
import FileManagerService from "../../../services/FileManager.service";
import { getFileIcon } from "../../../utils/fileIcon";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import message from "../../../utils/message";
import { useDispatch } from "react-redux";
import Pagination from "../../../components/Pagination";
import { downloadFile } from "../../../utils/downloadFiles";


const Trashed = ({ TrashedFile, getFiles, trashedFile, folderName, currentPage, TotalPage, currentSet, setCurrentSet, setcurrentPage, setSortDirection,setSortBy,sortBy,sortDirection }) => {


  const [show, setShow] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);

 
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const downloadExtensions = ['docx', 'xlsx',  'pptx'];
  
  // const handleShow = (fileUrl,fileName,fileExtension) => {
   
  //   if (downloadExtensions.includes(fileExtension)) {
  //     downloadFile(fileUrl, fileName)
      
      
  //   }else{
    
  //     setShow(true);
  //     setSelectedFileUrl(fileUrl);
  //   }

    
    
  // };


  const handleShow=async(fileId,fileUrl, fileName,fileExtension)=>{
    try{
    const res= await FileManagerService.filePreview(fileId)
      
        if (res) {
        
        const blob = res.data;
        const blobUrl=URL.createObjectURL(blob)
        setFileBlob(blobUrl); 
        setFileType(res.headers['content-type'])
        if (downloadExtensions.includes(fileExtension) &&blobUrl ){
         downloadFile(blobUrl, fileName);
         }else{
        setShow(true);
      }
      } else {
        console.error('Failed to fetch file');
      }
    }catch(err){
      setFileBlob(null)
      setShow(true);
      console.log(err)
    
    }
    
    
    
    
    }




  const handleRecover = async (id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.loading));
      await FileManagerService.RecoverFile(id)
      getFiles()
      trashedFile()
      dispatch(setMessage(message.fileRecover));
      } catch (err) {
      console.log(err)
      dispatch(setMessage(err.response.data.error));
      } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  }

  const TrashedRow = ({ item, handleRecover }) => (
    <tr>
     <td className=" file-name" >
        <div className="d-flex gap-1 align-items-center">
          <div role="button"><img src={getFileIcon(item.file_name)} alt={`${item.file_name} Icon`} className="file-icon" /></div>
          <span role="button" onClick={() => handleShow( item.id,item.file_url,item.file_name,item.file_type)} >{item.file_name}</span>
        </div>
      </td>
      <td className="">{item.uploaded_by}</td>
      <td>
        <span className={`badge rounded-pill ${item.status.toLowerCase() === 'approved' ? 'bg-success' : 'bg-secondary'}`}>{item.status.toUpperCase()}</span>
      </td>
      <td className="">{item.deleted_on}</td>
      <td className="recover text-center">
        <img src={recover} style={{ width: "20px" }} alt="" onClick={() => handleRecover(item.id)} />
      </td>
    </tr>
  );

  const handleSort = (column) => {
    const newDirection = sortBy === column && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortDirection(newDirection);
    
  };


  return (
    <>
      {TrashedFile.length > 0 ? (
        <div className="list-table table-responsive mt-2" >
          <div className="fw-bold py-2 mb-4 text-danger">
            <small> Note: Files in the trash will be permanently deleted after 30 days.</small>
          </div>

          <table className="table table-sm table-hover">
            <thead>
              <tr className="text-secondary fm-table-row">
                <th onClick={() => handleSort("file_name")}>Name {sortBy === "file_name" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
                <th  onClick={() => handleSort("uploaded_by")}>Uploaded by {sortBy === "uploaded_by" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
                <th  onClick={() => handleSort("approval_status")}>Status  {sortBy === "approval_status" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
                <th className="text-danger" onClick={() => handleSort("deleted_at")} >Deleted On {sortBy === "deleted_at" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
                <th className="recover text-center">Recover</th>
              </tr>
            </thead>
            <tbody>
              {TrashedFile.map((item, index) => (
                <TrashedRow key={index} item={item} handleRecover={handleRecover} />
              ))}
            </tbody>
            <Pagination
              currentPage={currentPage}
              TotalPage={TotalPage}
              currentSet={currentSet}
              setCurrentSet={setCurrentSet}
              setcurrentPage={setcurrentPage}
            />
          </table>
        </div>
      ) : (
        <div className="no-data-found">Trash is empty</div>
      )}


   <PreviewFileModal show={show} handleClose={handleClose} fileUrl={fileBlob}  fileType={fileType}/>
    </>
  );

}

export default Trashed;

