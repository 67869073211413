// ./components/AssigneePopup.jsx
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import UserService from "../../../services/user.service"; // Adjust the path as needed

const AssigneePopup = ({ show, onClose, clientId, clientName, portalAdminId }) => {
  const [assignees, setAssignees] = useState([]);

  useEffect(() => {
    if (show && clientId) {
      UserService.getAdminUserDetails(clientId)
        .then((response) => {
          if (response && Array.isArray(response.users)) {
            // Map users to include full_name (combine name and surname)
            const users = response.users.map((user) => ({
              ...user,
              full_name: user.surname ? `${user.name} ${user.surname}` : user.name,
            }));
            setAssignees(users);
          } else {
            setAssignees([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching associated users:", error);
          setAssignees([]);
        });
    }
  }, [show, clientId]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Assignees for
          <br />
          <span style={{ fontSize: "0.875rem", color: "gray" }}>{clientName}</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {assignees.length > 0 ? (
          <ul style={{ paddingLeft: "1.25rem" }}>
            {assignees.map((user) => {
              const displayName =
                user.id === portalAdminId
                  ? <strong>{user.full_name} (Admin)</strong>
                  : user.full_name;

              return (
                <li key={user.id} style={{ marginBottom: "0.5rem" }}>
                  {displayName}
                </li>
              );
            })}
          </ul>
        ) : (
          <p>No associated users found.</p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AssigneePopup;
