import React from "react";
import { folders, trashicon, foldericon } from "../../../utils/icons";
import { formatDateTime } from "../../../utils/dateTimeFormat";
import Pagination from "../../../components/Pagination";

const FolderRow = ({
  folder,
  setActiveTab,
  folderDetails,
  DeleteFolder,
  getFolder,
  handleShowFolderEdit,
  setFolderIdEdit,
}) => {
  // Handles tab change to show folder details
  const handleChangeTab = (id, name) => {
    setActiveTab("FolderDetails");
    folderDetails(id, name);
  };

  // Handles folder delete action
  const handleDelete = async (event, id) => {
    event.stopPropagation(); // Prevents row click event from triggering
    await DeleteFolder(id);
    getFolder(); // Fetch updated folders
  };

  // Handles edit folder action
  const handleEdit = (event, id) => {
    event.stopPropagation(); // Prevents row click event from triggering
    setFolderIdEdit(id);
    handleShowFolderEdit(true); // Opens folder edit modal
  };

  return (
    <tr onClick={() => handleChangeTab(folder.id, folder.name)}>
      <td className="file-name d-flex align-items-center gap-1 fw-bold">
        <img style={{width:"18px"}} src={folders} alt="Folder Icon" />
        <span>{folder.name}</span>
      </td>
      <td>{folder.fileCount}</td>
      <td>{formatDateTime(folder.createdAt)}</td>
      <td className="text-center">
        <img
          src={foldericon}
          alt="Edit Folder"
          style={{ width: "20px" }}
          onClick={(event) => handleEdit(event, folder.id)}
          role="button"
        />
      </td>
      <td className="text-center">
        <img
          src={trashicon}
          alt="Remove Folder"
          style={{ width: "20px" }}
          onClick={(event) => handleDelete(event, folder.id)}
          role="button"
        />
      </td>
    </tr>
  );
};
const Folders = ({
  Folder,
  setActiveTab,
  folderDetails,
  DeleteFolder,
  setFolderIdEdit,
  getFolder,
  handleShowFolderEdit,
  AllFiles,
  currentPage,
  TotalPage,
  currentSet,
  setCurrentSet,
  setcurrentPage,
  setSortDirection,setSortBy,sortBy,sortDirection
}) => {

  const handleSort = (column) => {
    const newDirection = sortBy === column && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortDirection(newDirection);
    
  };

  return (
    <>
      {Folder.length > 0 ? (
        <div className="list-table table-responsive">
          <table className="table table-hover table-sm mt-4">
            <thead>
              <tr className="text-secondary fm-table-row">
                <th width="40%"  onClick={() => handleSort("name")}>Folder Name {sortBy === "name" && (sortDirection === "ASC" ? "↑" : "↓")}
                </th>
                <th width="15%">Files</th>
                <th width="20%" onClick={() => handleSort("createdAt")}>Created On  {sortBy === "createdAt" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
                <th width="10%" className="text-center">Edit</th>
                <th width="10%" className="text-center">Remove</th>
              </tr>
            </thead>
            <tbody>
              {Folder.map((folder, index) => (
                <FolderRow
                  key={folder.id || index}
                  folder={folder}
                  setActiveTab={setActiveTab}
                  folderDetails={folderDetails}
                  DeleteFolder={DeleteFolder}
                  getFolder={getFolder}
                  handleShowFolderEdit={handleShowFolderEdit}
                  AllFiles={AllFiles}
                  setFolderIdEdit={setFolderIdEdit}
                />
              ))}
            </tbody>
          </table>

          {/* Note about folder removal */}
          <div className="text-muted mt-3">
            <small>Note: Removing a folder will not delete the files within it.</small>
          </div>

          {/* Pagination Component */}
          <Pagination
            currentPage={currentPage}
            TotalPage={TotalPage}
            currentSet={currentSet}
            setCurrentSet={setCurrentSet}
            setcurrentPage={setcurrentPage}
          />
        </div>
      ) : (
        <div className="no-data-found">No Folders found</div>
      )}
    </>
  );
};

export default Folders;

