import React, { useEffect, useState } from "react";
import { getFileIcon } from "../../../utils/fileIcon";
import { ProgressBar, Button } from "react-bootstrap"; // Import Bootstrap components
import FileManagerService from "../../../services/FileManager.service";
import { useParams } from "react-router-dom";
import { validateFileupload } from "../../../utils/validation";
import Error from "../../../components/Error";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import message from "../../../utils/message";
import { dragdropIcon } from "../../../utils/icons";

const allowedExtensions = ["pdf", "jpg", "jpeg", "png", "xlsx", "docx", "pptx"]; // Define allowed extensions
const maxSize = 5 * 1024 * 1024; // Max size of 5MB

const Filemanger_uploadfiles = ({ setShow, getFiles,client_Id=null }) => {
  const { id } = useParams();

  const currentFolderId = useSelector((state) => state.folder.currentFolderId);
  const loadingmessage = useSelector((state) => state.message.message);
  const isLoading = useSelector((state) => state.loading.loading);
  // const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const [invalidFiles, setInvalidFiles] = useState([]); // Track invalid files
  const [fileDetails, setFileDetails] = useState([]);
  const dispatch = useDispatch();
  const [uploadMessage, setUploadMessage] = useState("");
  const [dragOver, setDragOver] = useState(false); // Track drag state

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    const invalidFilesTemp = [];

    selectedFiles.forEach((file) => {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension) && file.size <= maxSize) {
        validFiles.push(file);
      } else {
        invalidFilesTemp.push({
          file,
          error:
            file.size > maxSize
              ? `File size exceeds ${maxSize / 1024 / 1024} MB`
              : `Invalid file type: ${fileExtension}`,
        });
      }
    });

    setFiles([...files, ...validFiles]);
    setInvalidFiles([...invalidFiles, ...invalidFilesTemp]);
  };

  // Handle drag over
  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    handleFileSelect(event);
  };

  const handleFileUpload = async (file, clientId ) => {
    console.log("client_Id --->>",client_Id)
    const formData = new FormData();
    formData.append("file", file);
    formData.append("clientId", clientId || client_Id);
    try {
      // setProgress(0);
      setUploading(true);
      setUploadMessage("Uploading...");
      const Filesdetails = await FileManagerService.uploadFile(formData);
      setFileDetails((prevDetails) => [
        ...prevDetails,
        {
          originalFileName: Filesdetails.originalFileName,
          uniqueFileName: Filesdetails.uniqueFileName,
          visibility: 0,
        },
      ]);
      setUploadIndex((prevIndex) => prevIndex + 1);
      setUploading(false);
      // setProgress(100);
      setUploadMessage("");
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrors({ fileuploadingError: error.response.data.errors[0].msg });
      setUploading(false);
    }
  };

  useEffect(() => {
    if (
      uploadIndex < files.length &&
      !uploading &&
      !errors.fileuploadingError
    ) {
      handleFileUpload(files[uploadIndex], id);
    }
  }, [uploadIndex, files, id, uploading, errors.fileuploadingError]);

  // const removeFile = (index) => {
  //   const updatedFiles = files.filter((_, i) => i !== index);
  //   setFiles(updatedFiles);
  // };

  const removeValidFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    // Also update fileDetails array if needed when removing a valid file
    setFileDetails((prevDetails) => prevDetails.filter((_, i) => i !== index));
  };

  const removeInvalidFile = (index) => {
    const updatedInvalidFiles = invalidFiles.filter((_, i) => i !== index);
    setInvalidFiles(updatedInvalidFiles);
  };

  const handleCheckboxChange = (index) => {
    setFileDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      if (updatedDetails[index]) {
        updatedDetails[index].visibility =
          updatedDetails[index].visibility === 1 ? 0 : 1;
      }
      return updatedDetails;
    });
  };

  const saveFile = async () => {
    const validationErrors = validateFileupload(files);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const data = {
      clientId: id || client_Id,
      files: fileDetails,
      folder_id: currentFolderId,
    };

    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.uploading));

      await FileManagerService.saveFile(data);
      setShow(false);
      // getFiles();
if(currentFolderId){

  getFiles(currentFolderId);
}else{
  getFiles()
}

      dispatch(setMessage(message.fileSaved));
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  const handleClose = async () => {
    setFiles([]);
    setInvalidFiles([]);
    setShow(false);
  };

  return (
    <div>
      <div
        className={`upload-container ${dragOver ? "drag-over" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="upload-box">
          <div className="upload-placeholder">
            <img src={dragdropIcon} width={60} />
            <div>
              Drag and Drop file here or{" "}
              <input type="file" multiple onChange={handleFileSelect} />
            </div>
          </div>
          <div className="upload-info">
            <p>Supported formats: PDF, JPG, PNG, XLSX, DOCX, PPTX</p>
            <p>Maximum size: 5MB</p>
          </div>
          {errors.files && <Error error={errors.files} />}
          {/* Show invalid files */}
          {invalidFiles.map(({ file, error }, index) => (
            <div key={`invalid-${index}`} className="file-upload invalid-file">
              <div className="file-info d-flex justify-content-between align-items-start">
                <div className="file-details">
                  <div className="file-name">{file.name}</div>
                  <div className="file-error">{error}</div>
                </div>
                <div className="remove-btn">
                  <button
                    className="btn btn-outline-secondary remove-btn btn-sm"
                    onClick={() => removeInvalidFile(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          ))}

          {/* Valid files */}
          {files.map((file, index) => (
            <div key={index} className="file-upload">
              <div className="file-info d-flex justify-content-between align-items-start">
                <div className="file-details">
                  <div className="file-icon">
                    <img
                      src={getFileIcon(file.name)}
                      alt={`${file.name} Icon`}
                      className="file-icon"
                    />
                  </div>
                  <div className="file-name">{file.name}</div>
                  <div className="file-size">
                    {file.size < 500 * 1024
                      ? `${(file.size / 1024).toFixed(2)} KB`
                      : `${(file.size / 1024 / 1024).toFixed(0)} MB`}
                  </div>
                </div>

                <div className="checkbox-container">
                  <label className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={fileDetails[index]?.visibility === 1}
                      onChange={() => handleCheckboxChange(index)}
                    />
                    <label className="form-check-label">Set to Visible</label>
                  </label>
                </div>

                <div className="remove-btn">
                  <button
                    className="btn btn-outline-secondary remove-btn btn-sm"
                    onClick={() => removeValidFile(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>

              {(uploadIndex === index || uploadIndex < index) && (
                <div
                  className={`file-status-overlay ${
                    uploadIndex === index ? "uploading" : "pending"
                  }`}
                >
                  <div className="status-message">
                    {uploadIndex === index ? "Uploading..." : "Pending..."}
                  </div>
                </div>
              )}
            </div>
          ))}

          <div className="action-buttons justify-content-between">
            <Button
              variant="outline-secondary"
              className="cancel-btn"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="save-btn"
              disabled={uploadIndex < files.length}
              onClick={saveFile}
            >
              {isLoading ? loadingmessage : "Save files"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filemanger_uploadfiles;
