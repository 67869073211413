import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Filemanager from "../pages/filemanager/Filemanager";

const FileManagerContainer = ({ onFileSelect }) => {
  const [clientId, setClientId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const client_id = query.get("client_id");
    if (client_id) {
      setClientId(client_id);
      setIsLoading(false); // Client ID is available, stop loading
    }
  }, [location.search]);

  if (isLoading) {
    return <p>Loading... Please wait.</p>;
  }

  return (
    // <div style={{ height: "100%", overflow: "auto" }} className="container-fluid">
    //   <Filemanager
    //     client_id={clientId} // Pass the client_id to Filemanager
    //     onFileSelect={(files) => console.log("Selected Files:", files)}
    //     selectMedia={true}
    //     task={true}
    //   />
    // </div>

    <div  className="disable">
   
      <Filemanager
        client_id={clientId} // Pass the client_id to Filemanager
        onFileSelect={(files) => console.log("Selected Files:", files)}
        selectMedia={true}
        task={true}
      />

  </div>
  );
};

export default FileManagerContainer;
