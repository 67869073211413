// ShareholderTable.jsx
import React from "react";

const ShareholderTable = ({ shareholders }) => {
  return (
    <table className="table table-sm">
      <thead>
        <tr>
          <th>Shareholder name</th>
          {/* <th>Surname</th> */}
          <th>SSN</th>
        </tr>
      </thead>
      <tbody>
        {shareholders.map((sh) => (
          <tr key={sh.id}>
            <td>{sh.name} {sh.surname}</td>
            {/* <td>{sh.surname}</td> */}
            <td>{sh.ssn}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ShareholderTable;
