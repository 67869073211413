import React, { useEffect, useState } from 'react'
import { Card,  Accordion, } from 'react-bootstrap';
import TaxAccountService from '../../../services/taxAccount.service';
import { useParams } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css'
const ClientDetails_Account = ({taxAccounts}) => {
  const { id } = useParams();
const [account,setaccount]=useState([])
const [showPasswords, setShowPasswords] = useState({})

console.log("taxAccounts",taxAccounts)

const accountDetails=async()=>{
try{
const account=await TaxAccountService.getAccountDetails(id)
setaccount(account.taxAcoount)
}catch(err){
console.log(err)
}
}

useEffect(()=>{
  accountDetails()
},[])



const togglePassword = (index) => {
  setShowPasswords((prev) => ({ ...prev, [index]: !prev[index] }))
}

  return (

    <>
      {/* Only render the Accordion.Item if taxAccounts has at least one entry */}
      {Array.isArray(taxAccounts) && taxAccounts.length > 0 && (
        <Accordion.Item>
          <Accordion.Header>
            <div className='d-flex align-items-center w-50 justify-content-between'>
              <span className='fs-6 fw-medium'>Tax account</span>
              <div>
                <button className='btn btn-xxs btn-outline-primary px-4'>Add</button>
              </div>
            </div>
          </Accordion.Header>

          <Accordion.Body>
            {taxAccounts.map((item, index) => (
              <Card className='mb-2' key={index}>
                <Card.Body>
                  {/* Account Type */}
                  {item.account_type && (
                    <div className="info-item">
                      <span className="label">Account Type:</span>
                      <span className="value">{item.account_type}</span>
                    </div>
                  )}

                  {/* Account No */}
                  {item.account_no && (
                    <div className="info-item">
                      {/* If you want a label, you can do so. Otherwise it's just the value. */}
                      <span className="label"></span>
                      <span className="value">{item.account_no}</span>
                    </div>
                  )}

                  {/* PIN */}
                  {item.pin && (
                    <div className="info-item">
                      <span className="label">PIN:</span>
                      <span className="value">{item.pin}</span>
                    </div>
                  )}

                  {/* Password */}
                  {item.password && (
                    <div className="info-item">
                      <span className="label">Password:</span>
                      <div className="d-flex align-items-center gap-2">
                        {/* Show hidden or actual password */}
                        <span className="value">
                          {showPasswords[index] ? item.password : '****'}
                        </span>
                        <i
                          className={`bi ${showPasswords[index] ? 'bi-eye-slash' : 'bi-eye'}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => togglePassword(index)}
                        />
                      </div>
                    </div>
                  )}

                  {/* Deposit Frequency */}
                  {item.deposit_frequency && (
                    <div className="info-item">
                      <span className="label">Deposit Frequency:</span>
                      <span className="value">{item.deposit_frequency}</span>
                    </div>
                  )}
                </Card.Body>
                <i className="bi bi-pencil-square edit-icon"></i>
              </Card>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      )}
    </>
  )
}

export default ClientDetails_Account 