import React, { useEffect, useState } from 'react'
import { Card , Accordion, } from 'react-bootstrap';
import getaddressDetailsService from '../../../services/Address.service';
import { useParams } from 'react-router-dom';

const ClientDetails_Address = ({addresses}) => {
  const { id } = useParams();
const [Address,setAddress]=useState([])

console.log("addresses",addresses)
const AddressDetails=async()=>{
  try{
const address=await getaddressDetailsService.getaddressDetails(id)
setAddress(address.Addresses)
// console.log(address.Addresses)
  }catch(err){
    console.log(err)
  }
}

useEffect(()=>{
  AddressDetails()
},[])
  return (
    <>
      {/* Only render the Accordion if addresses is an array and has at least one entry */}
      {Array.isArray(addresses) && addresses.length > 0 && (
        <Accordion.Item>
          <Accordion.Header>
            <div className='d-flex align-items-center w-50 justify-content-between'>
              <span className='fs-6 fw-medium'>Address</span>
              <div>
                <button className='btn btn-xxs btn-outline-primary px-4'>Add</button>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {addresses.map((addressItem, index) => (
              <Card className='mb-2' key={index}>
                <Card.Body>
                  {addressItem.type && (
                    <div className="text-muted text-uppercase" style={{ fontSize: '12px' }}>
                      {addressItem.type}
                    </div>
                  )}
                  {addressItem.address_line_1 && (
                    <div>{addressItem.address_line_1}</div>
                  )}
                </Card.Body>
                <i className="bi bi-pencil-square edit-icon"></i>
              </Card>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      )}
    </>
  )
}

export default ClientDetails_Address