import React, { useState } from "react";
import PreviewFileModal from "./Filemanager_PreviewFileModal";
import { folders, recover } from "../../../utils/icons";
import FileManagerService from "../../../services/FileManager.service";
import { getFileIcon } from "../../../utils/fileIcon";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import message from "../../../utils/message";
import { useDispatch } from "react-redux";
import Pagination from "../../../components/Pagination";
import { downloadFile } from "../../../utils/downloadFiles";
import FolderService from "../../../services/folder.service";

const Trashed = ({
  TrashedFile, // Updated to include both deletedFiles and deletedFolders
  getFiles,
  trashedFile,
  folderName,
  currentPage,
  TotalPage,
  currentSet,
  setCurrentSet,
  setcurrentPage,
  setSortDirection,
  setSortBy,
  sortBy,
  sortDirection,
  folderDetails,
}) => {
  const [show, setShow] = useState(false);
  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const downloadExtensions = ["docx", "xlsx", "pptx"];

  const handleShow = async (fileId, fileUrl, fileName, fileExtension) => {
    try {
      const res = await FileManagerService.filePreview(fileId);

      if (res) {
        const blob = res.data;
        const blobUrl = URL.createObjectURL(blob);
        setFileBlob(blobUrl);
        setFileType(res.headers["content-type"]);
        if (downloadExtensions.includes(fileExtension) && blobUrl) {
          downloadFile(blobUrl, fileName);
        } else {
          setShow(true);
        }
      } else {
        console.error("Failed to fetch file");
      }
    } catch (err) {
      setFileBlob(null);
      setShow(true);
      console.log(err);
    }
  };

  const handleRecover = async (id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.loading));
      await FileManagerService.RecoverFile(id);
      getFiles();
      trashedFile();
      dispatch(setMessage(message.fileRecover));
    } catch (err) {
      console.log(err);
      dispatch(setMessage(err.response.data.error));
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  const handleRecoverFolder = async (id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.loading));
      await FolderService.RecoverFolder(id);
      getFiles();
      trashedFile();
      dispatch(setMessage(message.folderRecover));
    } catch (err) {
      console.log(err);
      dispatch(setMessage(err.response.data.error));
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  console.log(TrashedFile);

  const TrashedRow = ({ item, handleRecover }) => (
    <tr>
      <td className="file-name">
        <div className="d-flex gap-1 align-items-center">
          <div role="button">
            <img
              src={item.file_name ? getFileIcon(item.file_name) : folders}
              alt={`${item.file_name || item.name} Icon`}
              className="file-icon"
              onClick={() =>
                item.file_name
                  ? handleShow(
                      item.id,
                      item.file_url,
                      item.file_name,
                      item.file_type
                    )
                  : folderDetails(item.id, item.name)
              }
            />
          </div>
          <span
            role="button"
            onClick={() =>
              item.file_name
                ? handleShow(
                    item.id,
                    item.file_url,
                    item.file_name,
                    item.file_type
                  )
                : folderDetails(item.id, item.name)
            }
          >
            {item.file_name || item.name}
          </span>
        </div>
      </td>
      <td className="">{item.uploaded_by || "-"}</td>
      <td>
        {item.file_name ? (
          <span
            className={`badge rounded-pill ${
              item.status.toLowerCase() === "approved"
                ? "bg-success"
                : "bg-secondary"
            }`}
          >
            {item.status.toUpperCase()}
          </span>
        ) : (
          "-"
        )}
      </td>
      <td className="">{item.deleted_on}</td>
      <td className="recover text-center">
        <img
          src={recover}
          style={{ width: "20px" }}
          alt="Recover"
          onClick={() => handleRecover(item.id)}
        />
      </td>
    </tr>
  );

  const handleSort = (column) => {
    const newDirection =
      sortBy === column && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortDirection(newDirection);
  };

  return (
    <>
      {TrashedFile.deletedFiles.total > 0 ||
      TrashedFile.deletedFolders.total > 0 ? (
        <div className="list-table table-responsive mt-2">
          <div className="fw-bold py-2 mb-4 text-danger">
            <small>
              Note: Files and folders in the trash will be permanently deleted
              after 30 days.
            </small>
          </div>

          <table className="table table-sm table-hover">
            <thead>
              <tr className="text-secondary fm-table-row">
                <th onClick={() => handleSort("file_name")}>
                  Name{" "}
                  {sortBy === "file_name" &&
                    (sortDirection === "ASC" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("uploaded_by")}>
                  Uploaded by{" "}
                  {sortBy === "uploaded_by" &&
                    (sortDirection === "ASC" ? "↑" : "↓")}
                </th>
                <th onClick={() => handleSort("approval_status")}>
                  Status{" "}
                  {sortBy === "approval_status" &&
                    (sortDirection === "ASC" ? "↑" : "↓")}
                </th>
                <th
                  className="text-danger"
                  onClick={() => handleSort("deleted_at")}
                >
                  Deleted On{" "}
                  {sortBy === "deleted_at" &&
                    (sortDirection === "ASC" ? "↑" : "↓")}
                </th>
                <th className="recover text-center">Recover</th>
              </tr>
            </thead>
            <tbody>
              {/* Render deleted folders */}
              {TrashedFile.deletedFolders.data.map((folder, index) => (
                <TrashedRow
                  key={`folder-${index}`}
                  item={folder}
                  handleRecover={handleRecoverFolder}
                />
              ))}

              {/* Render deleted files */}
              {TrashedFile.deletedFiles.data.map((file, index) => (
                <TrashedRow
                  key={`file-${index}`}
                  item={file}
                  handleRecover={handleRecover}
                />
              ))}
            </tbody>
            <Pagination
              currentPage={currentPage}
              TotalPage={TotalPage}
              currentSet={currentSet}
              setCurrentSet={setCurrentSet}
              setcurrentPage={setcurrentPage}
            />
          </table>
        </div>
      ) : (
        <div className="no-data-found">Trash is empty</div>
      )}

      <PreviewFileModal
        show={show}
        handleClose={handleClose}
        fileUrl={fileBlob}
        fileType={fileType}
      />
    </>
  );
};

export default Trashed;
