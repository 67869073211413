// AssignPortalAdminPopup.js
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import UserService from '../../../services/user.service';
import clientService from '../../../services/client.service';

const AssignPortalAdminPopup = ({ show, handleClose, clientName, clientId }) => {
  const [adminUsers, setAdminUsers] = useState([]);
  
  const [selectedAdmin, setSelectedAdmin] = useState('');

  // Fetch admin users when the modal opens
  useEffect(() => {
    if (show) {
      fetchAdminUsers(clientId);
    }
  }, [show, clientId]);

  const fetchAdminUsers = async (clientId) => {
    try {
      const response = await UserService.getAdminUserDetails(clientId);
      // console.log(response)
      if (response.user && response.user) {
        setAdminUsers([response.user]); 
      }
    } catch (error) {
      console.error('Error fetching admin users:', error);
    }
  };

  const handleAdminSelect = (e) => {
    setSelectedAdmin(e.target.value); // Set the selected admin user ID
  };

  const handleSubmit = async() => {

try{
    const data={
        portal_admin:selectedAdmin
    }
    await clientService.update_admin_portal(clientId,data)
    handleClose(); // Close the modal after submission
}catch(err){
console.log(err)
}



    
  
  };

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Assign Portal Admin for {clientName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Select an admin to assign to this client:</p>
      <Form>
        <Form.Group controlId="adminSelect">
          <Form.Label>Admin User</Form.Label>
          <Form.Control as="select" value={selectedAdmin} onChange={handleAdminSelect}>
            <option value="">Select an admin</option>
            {adminUsers.map((admin) => (
              <option key={admin.id} value={admin.id}>
                {`${admin.name} ${admin.surname} (${admin.email})`}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleSubmit}>
        Assign Admin
      </Button>
    </Modal.Footer>
  </Modal>
  );
};

export default AssignPortalAdminPopup;
