import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";

const PreviewFileModal = ({ show, handleClose, fileUrl,fileType }) => {

 

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {fileUrl ? (
        <>
         
          {fileType === 'application/pdf' && (
            <iframe src={fileUrl} width="100%" height="600px" title="File Viewer" />
          )}

         
          {fileType.startsWith('image/') && (
            <img src={fileUrl} alt="File Preview" width="100%" height="auto" />
          )}

        </>
      ) : (
        <div style={{ textAlign: "center", padding: "20px", color: "red" }}>
            <h5>File not found</h5>
          </div>
      )}
      </Modal.Body>
    </Modal>
  );
};

export default PreviewFileModal;
