import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/adm_logo_white.png";
import Cookies from 'js-cookie';




// import io from "socket.io-client";
import NotificationService from "../../services/notification.service";
import { notificationIcon } from "../../utils/icons";
import NotificationBox from "../NotificationBox";
import { Badge } from "react-bootstrap";
import { getFullNameFromToken, getUserIdFromToken } from '../../utils/decodeToken';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRoles } from "../../feature/Roles_Permission/roles";
// const socket = io(process.env.REACT_APP_API_URL);

const Header = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [notifications, setnotifications] = useState([]);
  // console.log(notifications)
  const [userId, setuserId] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);
  const [notificationType, setnotificationType] = useState("");
  const [activeItem, setActiveItem] = useState("clients");
  const [username, setusername] = useState("")



  const storedUserId = getUserIdFromToken();

  const roles = useSelector((state) => state.Roles.roles);
  // console.log(roles)
  const permissions = roles.permissions || [];
 // console.log("permissions", permissions)

  // const navItems = [
  //   // { name: 'Tasks Dashboard', path: `/tasks`, key: 'dashboard'},
  //   { name: "My Clients", path: "/clients", key: "clients" },
  //   { name: "Chats", path: "/chats", key: "chats" },
  //   // ...(permissions.includes("chats") ? [{ name: "Chats", path: "/chats", key: "chats" }] : []),
  //   { name: "Client Portal Users", path: "/Client_portal_user", key: "Client_portal_user" },



  // ];


  const phplinks = [
    { href: `${process.env.REACT_APP_PHP_ADMONE}/tasks`, text: 'Tasks Dashboard', permission: 'my-tasks' },
    { href: `${process.env.REACT_APP_PHP_ADMONE}/activity-log`, text: 'Activity Log', permission: '' },
    { href: `${process.env.REACT_APP_PHP_ADMONE}/pipelines`, text: 'Pipelines', permission: 'my-pipelines' },
    { href: `${process.env.REACT_APP_PHP_ADMONE}/team`, text: 'My Team', permission: 'my-team' },
    { href: `${process.env.REACT_APP_PHP_ADMONE}/clients`, text: 'My Clients', permission: 'my-clients' }
  ];

  const navItems = [
   // ...(permissions.includes("my-clients") ? [{ name: "My Clients", path: "/clients", key: "clients" }] : []),
    ...(permissions.includes("chats") ? [{ name: "Client Chats", path: "/chats", key: "chats" }] : []),
    ...(permissions.includes("manage-clientportal-user") ? [{ name: "Client Portal Users", path: "/Client_portal_user", key: "Client_portal_user" }] : []),
  ];

  const fetchNotification = async () => {

    setuserId(storedUserId);
    try {
      const getNotification = await NotificationService.getNotifications();
      const models = notifications.map((notification) => notification.model);
      setnotificationType(models.join(", "));
      setUnreadCount(
        getNotification.notifications.filter(
          (notification) => notification.unread == null
        ).length
      );

      // console.log(getNotification)
      setnotifications(getNotification.notifications);
    } catch (err) {
      console.log(err);
    }
  };

  const updateUnread = async () => {
    try {
      const updateunread = await NotificationService.updateUnread(userId);
      fetchNotification();
      // console.log(updateunread);
    } catch (err) {
      console.log(err);
    }
  };

  // let userName = localStorage.getItem('userName');

  useEffect(() => {

    fetchNotification();


    const storedFullname = getFullNameFromToken();
    setusername(storedFullname)

    dispatch(fetchAllRoles({ userId: storedUserId }));

  }, []);



  const [fetchNotificationBysocket, setfetchNotificationBysocket] =
    useState(false);

  useEffect(() => {
    if (fetchNotificationBysocket == true) {
      fetchNotification();
      setfetchNotificationBysocket(false);

    }
  }, [fetchNotificationBysocket == true]);

  // socket.on("connects", () => {
  //   setfetchNotificationBysocket(true);
  //   console.log("Connected to socket.io server");
  // });

  // socket.on("notificationUpdate", () => {
  //   console.log("Notification update received:");
  //   setfetchNotificationBysocket(true);

  // });

  // socket.on("disconnects", () => {
  //   setfetchNotificationBysocket(false);

  // });

  const handleItemClick = (item) => { setActiveItem(item) };
  useEffect(() => {

    const currentPath = location.pathname;
    const activeNavItem = navItems.find(item => currentPath.includes(item.path));
    if (activeNavItem) {
      setActiveItem(activeNavItem.key);
    }
  }, [location.pathname]);

  const navigate = useNavigate()
  const handleLogout = () => {

    Cookies.remove('admone', { path: '/', domain: process.env.REACT_APP_COOKIE_URL || undefined });
    navigate("/login")

  }


  const handleUpdateNotification = () => {
    updateUnread()
  }
  return (
    <header id="layout-header" class="header">
      <nav className="navbar fixed-top navbar-expand-lg bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/tasks">
            <img src={logo} height="35" alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse main-menu"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

              {/* <li class="nav-item"><a class="nav-link " href="https://admone.app/tasks">Tasks Dashboard</a></li>
              <li class="nav-item"><a class="nav-link " href="https://admone.app/activity-log">Activity Log</a></li>
              <li class="nav-item"><a class="nav-link " href="https://admone.app/pipelines">Pipelines</a></li>
              <li class="nav-item"><a class="nav-link " href="https://admone.app/team">My Team</a></li>
              <li class="nav-item"> <a class="nav-link " href="https://admone.app/clients">My Clients</a></li> */}

          

              {phplinks.map(link => (
                  (!link.permission || permissions.includes(link.permission))  && (
                    <li key={link.text} className="nav-item">
                      <a className="nav-link" href={link.href}>{link.text}</a>
                    </li>
                  )
                ))}

              {navItems.map((item) => (
                <li className="nav-item">
                  <Link
                    to={item.path}
                    className={`nav-link ${activeItem == item.key ? "active" : ""
                      }`}
                    onClick={() => handleItemClick(item.key)}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="navbar-nav header__right align-items-center">


            <div className="notifications">

              <img
                src={notificationIcon}
                height="30"
                alt="Notifications"
                class="dropdown-toggle me-2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={handleUpdateNotification}
              />

              {unreadCount > 0 && (
                <Badge bg="danger" className="position-absolute top-10 start-90 translate-middle p-2 rounded-circle">
                  {unreadCount}
                </Badge>
              )}
              <NotificationBox

                notifications={notifications}


              />

            </div>

            <div className="nav-item dropdown d-flex align-items-center">

              <a
                className="nav-link dropdown-toggle my-account"
                href="https://admone.app/my-profile"
                id="account_dropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {username}
              </a>

              {/* <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="account_dropdown" >
                
                <li className="ps-2">
                  <a className="dropdown-item" href="http://adm.test/my-profile">My Profile</a>
                </li>
                <li className="ps-2">
                  <a className="dropdown-item" href="#" onClick={handleLogout}>Logout</a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </nav>

    </header>
  );
};

export default Header;
