import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import FileManagerService from "../services/FileManager.service";
import Pagination from "../components/Pagination";
import { getFileIcon } from "../utils/fileIcon";

const MediaManager = ({ onSelect, client_id }) => {
  const [show, setShow] = useState(false);
  const [existingFiles, setExistingFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const baseUrl = process.env.REACT_APP_MEDIA_URL;

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setCurrentPage(1);
    setCurrentSet(1);
    setSearchQuery("");
    setShow(true);
  };

  useEffect(() => {
    if (show) {
      fetchExistingFiles(currentPage, searchQuery);
    }
  }, [show, currentPage, searchQuery]);

  const fetchExistingFiles = async (page, query) => {
    const data = {
      search: query,
      clientId: client_id,
      page,
      limit: 25,
    };
    try {
      const response = await FileManagerService.getFile(data);
      setExistingFiles(response.files || []);
      setTotalPages(response.totalPages || 1);
    } catch (error) {
      console.error("Error fetching existing files:", error);
    }
  };

  const saveFile = async (fileDetails) => {
    const data = {
      clientId: client_id,
      files: fileDetails,
    };

    try {
      await FileManagerService.saveFile(data);
    } catch (error) {
      console.error("Error saving file:", error);
      setFileError("File save failed. Please try again.");
    }
  };

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    const formData = new FormData();

    files.forEach((file) => formData.append("file", file));
    formData.append("clientId", client_id);

    try {
      setIsUploading(true);
      const response = await FileManagerService.uploadFile(formData);

      const fileDetailsArray = response.map((file) => ({
        originalFileName: file.originalFileName,
        uniqueFileName: file.uniqueFileName,
        visibility: 1,
      }));
      await saveFile(fileDetailsArray);

      fetchExistingFiles(currentPage, searchQuery);
    } catch (error) {
      console.error("Error uploading file:", error);
      setFileError("File upload failed. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileSelect = (file) => {
    setSelectedFiles((prev) =>
      prev.includes(file) ? prev.filter((f) => f !== file) : [...prev, file]
    );
  };

  const handleCheckboxChange = (file) => {
    setSelectedFiles((prev) =>
      prev.find((f) => f.id === file.id)
        ? prev.filter((f) => f.id !== file.id)
        : [...prev, file]
    );
  };

  const handleConfirm = () => {
    const selectedFilesInfo = selectedFiles.map((file) => ({
      id: file.id,
      file_name: file.file_name,
    }));
    onSelect(selectedFilesInfo);
    setSelectedFiles([]);
    handleClose();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Attach Files
      </Button> */}
      <button
        className="btn btn-sm btn-outline-primary ms-2 "
        onClick={handleShow}
      >
        Attach Files
      </button>

      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Files</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-1">
          <Form > 
            <Form.Group controlId="uploadFiles">
              <div className="d-flex  align-items-center justify-content-between ">
                <div className="w-50 d-flex">
                  <Form.Label className="me-1" style={{ width: "200px" }}>
                    Upload New Files
                  </Form.Label>
                  <Form.Control
                    type="file"
                    multiple
                    onChange={handleFileUpload}
                    className="form-control form-control-sm "
                    disabled={isUploading}
                  />
                </div>
                {/* <div className="text-end d-flex justify-content-end"> */}
                <Form.Group controlId="searchFiles" className=" w-25">
                  <Form.Control
                    type="text"
                    placeholder="Search by filename"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className=" align-self-end  form-control form-control-sm "
                  />
                </Form.Group>
              </div>
              {/* </div> */}

              {fileError && <p className="text-danger">{fileError}</p>}
            </Form.Group>
          </Form>

          <div className="table-responsive mt-3">
            <table className="table table-sm table-bordered table-hover">
              <thead>
                <tr>
                  <th className="invisible">
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        setSelectedFiles(
                          e.target.checked ? [...existingFiles] : []
                        )
                      }
                      checked={
                        existingFiles.length > 0 &&
                        selectedFiles.length === existingFiles.length
                      }
                    />
                  </th>
                  <th>Name</th>
                  <th>Uploaded By</th>
                  <th>Uploaded On</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {existingFiles.length > 0 ? (
                  existingFiles.map((file) => (
                    <tr key={file.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedFiles.find((f) => f.id === file.id)}
                          onChange={() => handleCheckboxChange(file)}
                        />
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <img
                            src={getFileIcon(file.file_name)}
                            alt="icon"
                            style={{ width: "15px", marginRight: "10px" }}
                          />
                          {file.file_name}
                        </div>
                      </td>
                      <td>{file.uploaded_by}</td>
                      <td>{file.uploaded_on}</td>
                      <td>
                        <span
                          className={`badge ${
                            file.status.toLowerCase() === "approved"
                              ? "bg-success"
                              : "bg-secondary"
                          }`}
                        >
                          {file.status}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No files found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Pagination
              currentPage={currentPage}
              TotalPage={totalPages}
              currentSet={currentSet}
              setCurrentSet={setCurrentSet}
              setcurrentPage={setCurrentPage}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={selectedFiles.length === 0}
          >
            Confirm Selection
          </Button> */}

          <button
            className="btn btn-sm btn-outline-primary "
            onClick={handleClose}
          >
            Close
          </button>

          <button
            className="btn btn-sm btn-primary"
            onClick={handleConfirm}
            disabled={selectedFiles.length === 0}
          >
            Confirm Selection
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MediaManager;
