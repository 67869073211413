
import axiosInstance from '../utils/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/clients/`;
const getShareholderDetails= async (id ) => {

    const res = await axiosInstance.get(API_URL +"shareholders")

    return res.data;

}



const shareholderService = {
 
    getShareholderDetails
   
  }
  
  export default shareholderService;