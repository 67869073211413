import React, { useEffect, useState } from 'react'
import { Card,  Accordion, } from 'react-bootstrap';
import shareholderService from '../../../services/shareholder.service';
import { useParams } from 'react-router-dom';

export const ClientDetails_ShareHolder = ({shareholders}) => {
  const { id } = useParams();
  const[shareHolderDetails,setshareHolderDetails]=useState([])

  const getShareholderDetails=async()=>{
    try{
const shareholder=await shareholderService.getShareholderDetails(id)
// console.log(shareholder)
setshareHolderDetails(shareholder.shareholderDetails)
    }catch(err){
        console.log(err)
    }
}
useEffect(()=>{
  getShareholderDetails()
},[])
  return (
    <>
      {/* Only render if shareholders is an array and has at least 1 element */}
      {Array.isArray(shareholders) && shareholders.length > 0 && (
        <Accordion.Item>
          <Accordion.Header>
            <div className='d-flex align-items-center w-50 justify-content-between'>
              <span className='fs-6 fw-medium'>Shareholders</span>
              <div>
                <button className='btn btn-xxs btn-outline-primary px-4'>Add</button>
              </div>
            </div>
          </Accordion.Header>

          <Accordion.Body>
            {shareholders.map((item, idx) => (
              <Card className='mb-2' key={idx}>
                <Card.Body>
                  {/* Name */}
                  {item.full_name && (
                    <div className="info-item">
                      <span className="label">Name:</span>
                      <span className="value">{item.full_name}</span>
                    </div>
                  )}

                  {/* Percentage */}
                  {item.percentage && (
                    <div className="info-item">
                      <span className="label">Percentage:</span>
                      <span className="value">{item.percentage}</span>
                    </div>
                  )}

                  {/* SSN */}
                  {item.ssn && (
                    <div className="info-item">
                      <span className="label">SSN:</span>
                      <span className="value">{item.ssn}</span>
                    </div>
                  )}

                  {/* Address */}
                  {item.address && (
                    <div className="info-item">
                      <span className="label">Address:</span>
                      <span className="value">{item.address}</span>
                    </div>
                  )}
                </Card.Body>
                <i className="bi bi-pencil-square edit-icon"></i>
              </Card>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      )}
    </>
  )
}
