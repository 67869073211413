import axiosInstance from "../utils/axiosInstance";

const API_URL = `${process.env.REACT_APP_API_URL}/api/admin/v1/chat/`;
const getAllThreads = async (data) => {

const res = await axiosInstance.post(API_URL + "threads",data,{
        headers: {
          'Content-Type': 'application/json',
        },
    })

    return res.data;

}

const getMessage=async(Threadid,data)=>{
    const res = await axiosInstance.get(API_URL + `/thread/${Threadid}/getChats`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;  
}






const SendNewMessage = async (Threadid,data) => {
   
    const res = await axiosInstance.post(API_URL + `/thread/${Threadid}/sendNewMessage`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}



const createNewThread = async (data) => {
   
    const res = await axiosInstance.post(API_URL +`/createNewThread`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}


const deleteThread = async (threadId) => {
  try {
    const response = await axiosInstance.delete(`${API_URL}/${threadId}/DeleteThread`);
    return response.data;
  } catch (error) {
    console.error('Error deleting thread:', error);
    throw error; // Throw the error to handle it in the calling function
  }
};




const chatsService={
    getAllThreads,SendNewMessage,getMessage,createNewThread,deleteThread
 

}

export default chatsService
