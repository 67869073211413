import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Layout from "../../../components/layout/Layout";
import shareholderService from "../../../services/shareholder.service";

const Shareholder = () => {
  const { id } = useParams();
  const [shareholders, setShareholders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchShareholders = async () => {
      try {
        const data = await shareholderService.getShareholderDetails(id);
        setShareholders(data.shareholders);
      } catch (err) {
        setError("Failed to fetch shareholder details");
      } finally {
        setLoading(false);
      }
    };

    fetchShareholders();
  }, [id]);



  const navigateToClients = (shareholderId) => {
    navigate(`/clients?shareholder_id=${shareholderId}`);
  };
  return (
    <Layout title="Shareholders">
      <div className="card">
        <div className="card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="card-title mb-0">Shareholders</h4>
            <input
              type="text"
              className="form-control form-control-sm w-auto"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-danger">{error}</p>
          ) : (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Shareholder Name</th>
                  <th>SSN Number</th>
                  <th>Entities</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {shareholders.map((shareholder) => (
                  <tr key={shareholder.id}>
                    <td>{`${shareholder.name} ${shareholder.surname}`}</td>
                    <td>{shareholder.ssn}</td>
                    <td>{shareholder.entities}</td>
                    <td>
                      <button className="btn btn-primary btn-sm" onClick={() => navigateToClients(shareholder.id)}>See Entities</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Shareholder;