import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Accordion } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Createbank } from '../../../feature/bank/bank'; // <-- Keep the Createbank import

const ClientDetails_Banks = ({ banks }) => {
  console.log("banks", banks)
  const { id } = useParams();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    bankname: Yup.string().required('Name is required'),
    routingno: Yup.string().required('Routing No is required'),
    Website: Yup.string().required('Website is required'),
    streetName: Yup.string().required('Street Name & Number is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.number().required('Zip is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.number().required('State is required'),
    accountno: Yup.number().required('Account No is required'),
    Password: Yup.number().required('Password is required'),
    notes: Yup.string().required('Country is required')
  });

  const [showAddpopup, setAddpopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);

  const [bankList, setbankList] = useState([]);
  console.log("bankList",bankList)

  const handleClose = () => setAddpopup(false);
  const handleShow = () => setAddpopup(true);
  const editHandleClose = () => setShowEditPopup(false);
  const editHandleShow = () => setShowEditPopup(true);

  // Instead of fetching from Redux, use the banks prop
  useEffect(() => {
  setbankList(banks);
  }, [banks]);

  var dba = useSelector((state) => state.dba.dba);

  const handleCreate = async (value) => {
    const res = await dispatch(Createbank(value)).unwrap();
    console.log(res);
    alert("save");
  };

  return (
    <>
    {Array.isArray(banks) && banks.length > 0 && (
        <Accordion.Item>
          <Accordion.Header className=''>
            <div className='d-flex align-items-center w-50 justify-content-between'>
              <span className='fs-6 fw-medium'>Banks</span>
              <div>
                <button className='btn btn-xxs btn-outline-primary px-4' onClick={handleShow}>
                  Add
                </button>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className=''>
            {banks.map((bankdetails, index) => (
              <Card className="bg-info bg-opacity-10 mb-2" key={bankdetails.id || index}>
                <Card.Body>
                  {bankdetails.name && (
                    <div className="info-item">
                      <span className="label">Bank Name:</span>
                      <span className="value">{bankdetails.name}</span>
                    </div>
                  )}
                  {bankdetails.account_no && (
                    <div className="info-item">
                      <span className="label">Account Number:</span>
                      <span className="value">{bankdetails.account_no}</span>
                    </div>
                  )}
                  {bankdetails.routing_no && (
                    <div className="info-item">
                      <span className="label">Routing Number:</span>
                      <span className="value">{bankdetails.routing_no}</span>
                    </div>
                  )}
                  {bankdetails.website && (
                    <div className="info-item">
                      <span className="label">Website:</span>
                      <span className="value">
                        <a
                          href={bankdetails.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {bankdetails.website}
                        </a>
                      </span>
                    </div>
                  )}
                  {bankdetails.notes && (
                    <div className="info-item">
                      <span className="label">Notes:</span><br />
                      <span className="value">{bankdetails.notes}</span>
                    </div>
                  )}
                </Card.Body>
                <i className="bi bi-pencil-square edit-icon" onClick={editHandleShow}></i>
              </Card>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      )}


      <Modal show={showAddpopup} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Create Banks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col col-md-12 p-4">
            <Formik
              // initialValues can be defined as needed
              onSubmit={(values, { setSubmitting }) => {
                const finalValue = { ...values, client_id: id };
                console.log(finalValue);
                setSubmitting(false);
                // handleCreate(finalValue);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="name">Bank Name </label>
                        <div>
                          <Field type="text" name="name" className="form-control" />
                          <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="account_no">Account No </label>
                        <div>
                          <Field type="text" name="account_no" className="form-control" />
                          <ErrorMessage name="account_no" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="routing_no">Routing no</label>
                        <div>
                          <Field type="text" name="routing_no" className="form-control" />
                          <ErrorMessage name="routing_no" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="website">Website</label>
                        <div>
                          <Field type="text" name="website" className="form-control" />
                          <ErrorMessage name="website" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <i className="bi bi-toggle-on fs-4"></i>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="streetName">DBA</label>
                        <div>
                          <Field as="select" className="border-0 w-100" name="dba_id">
                            <option value="">
                              <input type="search" />
                            </option>
                            {dba.map((dbaDetails) => (
                              <option key={dbaDetails.id} value={dbaDetails.id}>
                                {dbaDetails.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <span className='fs-sm'>(optional)</span>
                      </div>
                    </div>
                    <hr />
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="user_id">User id</label>
                        <div>
                          <Field type="text" name="user_id" className="form-control" />
                          <ErrorMessage name="user_id" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="password">Password</label>
                        <div>
                          <Field type="text" name="password" className="form-control" />
                          <ErrorMessage name="password" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="secret_questions_answers">Secret questions answers</label>
                        <div className='my-2'>
                          <Button variant="outline-primary">Add new item</Button>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label htmlFor="notes">Notes</label>
                        <div>
                          <Field as="textarea" name="notes" className="border-0 w-100" />
                          <ErrorMessage name="notes" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                    <div className='justify-content-start mt-4 d-flex gap-2'>
                      <div>
                        <Button type='submit' variant="primary">
                          Submit
                        </Button>
                      </div>
                      <div>
                        <Button variant="outline-primary" onClick={handleClose}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>

      {/* DBAS on click edit form */}
      <Modal show={showEditPopup} onHide={editHandleClose} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>KWIK FOOD MART</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                name: '',
                address: 'Enter a location',
                street: '13760 NORTH',
                city: 'HOUSTON',
                zip: '77067',
                country: 'Houston',
                state: '51'
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                setSubmitting(false);
                handleClose(); // Close modal after submission
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <div className="row mb-3">
                      <label htmlFor="name">Name</label>
                      <div>
                        <Field type="text" name="name" className="form-control" />
                        <ErrorMessage name="name" component="div" className="text-danger" />
                      </div>
                    </div>
                    <span className='fs-5 fw-medium'>Property Address</span>
                    <hr />
                    <div className="row mb-3">
                      <label htmlFor="address">Address</label>
                      <div>
                        <Field type="text" name="address" className="form-control" />
                        <ErrorMessage name="address" component="div" className="text-danger" />
                      </div>
                      <p>(Type of search address)</p>
                    </div>
                    <div className="row mb-3">
                      <label htmlFor="street">Street Name &amp; Number</label>
                      <div>
                        <Field type="number" name="street" className="form-control" />
                        <ErrorMessage name="street" component="div" className="text-danger" />
                      </div>
                    </div>
                    <div className="row mb-3 ">
                      <div className="col-sm-6">
                        <label htmlFor="city">City</label>
                        <div>
                          <Field type="text" name="city" className="form-control" />
                          <ErrorMessage name="city" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="zip">Zip</label>
                        <div>
                          <Field type="text" name="zip" className="form-control" />
                          <ErrorMessage name="zip" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 ">
                      <div className="col-sm-6">
                        <label htmlFor="country">Country</label>
                        <div>
                          <Field type="text" name="country" className="form-control" />
                          <ErrorMessage name="country" component="div" className="text-danger" />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="state">State</label>
                        <div>
                          <Field type="text" name="state" className="form-control" />
                          <ErrorMessage name="state" component="div" className="text-danger" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className='col-md-12 justify-content-start'>
                        <i className="bi bi-toggle-on fs-4"></i>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          <div>
            <Button className='me-2' variant="primary" onClick={editHandleClose}>
              Save
            </Button>
            <Button variant="outline-primary" onClick={editHandleClose}>
              Cancel
            </Button>
          </div>
          <div>
            <Button variant="outline-danger" onClick={editHandleClose}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ClientDetails_Banks;
