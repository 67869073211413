import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";

import clientService from "../../services/client.service";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { useDispatch, useSelector } from "react-redux";
import client, { fetchAllclients } from "../../feature/client/client";

import { getUserIdFromToken } from "../../utils/decodeToken";
import PortalAdminModalContent from "./components/portalAdmin";
import { Modal } from "react-bootstrap";
import UserService from "../../services/user.service";
import ShareholderTable from "./components/ShareholderPopup";
import { exportToExcel } from "../../utils/exportExcel";
import AssigneeModal from "./components/AssigneeModal";

const inputStyle = {
  borderColor: "#1E468E",
  position: "relative",
  width: "100%",
};

const iconStyle = {
  position: "absolute",
  color: "#1E468E",
  fontSize: "19px",
  right: "1px",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: "10",
};

const Clients = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const DetailsPage = (id) => {
    // navigate(`/client/${id}`);
    window.location.href = `${process.env.REACT_APP_PHP_ADMONE}/client/${id}`;
  };

  const TaskPage = (id) => {
    // navigate(`/client/${id}`);
    window.location.href = `${process.env.REACT_APP_PHP_ADMONE}/new-task/client/${id}`;
  };
  const [searchTerm, setSearchTerm] = useState("");
 

 
  const shareholderId = queryParams.get("shareholder_id");


  const [clientList, setclientList] = useState([]);
  const [CurrentPage, setCurrentPage] = useState("");
  const [TotalPage, setTotalPage] = useState("");
  const [TotalClient, setTotalClient] = useState("");
  const [currentSet, setCurrentSet] = useState(1);
  const [clearField, setclearField] = useState(false);
  const [activeArrow, setActiveArrow] = useState("");
  const [sortType, setsortType] = useState("new");
  const [userId, setuserId] = useState("");
  const [searchShareholder, setSearchShareholder] = useState("");
  const [shareholderName, setShareholderName] = useState("");
  const [showPortalAdminModal, setShowPortalAdminModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  // Dummy data for connected users; replace with your API call as needed.
  const [adminUsers, setAdminUsers] = useState([]); // Store connected admin users
  const [showShareholderModal, setShowShareholderModal] = useState(false);
  const [selectedShareholders, setSelectedShareholders] = useState([]);

  const [showAssigneeModal, setShowAssigneeModal] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState(null);
  const [ClearFilter, setClearFilter] = useState(false);


  const [searchClientUser, setSearchClientUser] = useState("");
  // Function to fetch connected admin users for a given client
  const fetchAdminUsers = async (clientId) => {
    try {
      const response = await UserService.getAdminUserDetails(clientId);

      console.log("response", response);  // Should show { users: [ ... ] }
  
      // Use `response.users` instead of `response.user`
      if (response && Array.isArray(response.users)) {
        setAdminUsers(response.users);

      } else {
        setAdminUsers([]);
      }
    } catch (error) {
      console.error("Error fetching admin users:", error);
      setAdminUsers([]);
    }
  };

  // const fetchClient = useCallback(
  //   async (noOfPage, userId) => {
     
  //     try {
  //       const clients = await dispatch(
  //         fetchAllclients({
  //           datas: { search_keyword: searchTerm,shareholder_search_keyword: searchShareholder,user_id: userId,clientuser_search_keyword: searchClientUser, },
  //           params: { sort: sortType, page: noOfPage,shareholder_id: shareholderId },
  //         })
  //       ).unwrap();
  //       // console.log(clients)

  //       setclientList(clients.data.sortedClients);
  //       setCurrentPage(clients.data.page);
  //       setTotalPage(clients.data.TotalPage);
  //       setTotalClient(clients.data.TotalClients);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   },
  //   [ sortType]
  // );

  const fetchClient = async (noOfPage, userIdVal) => {
    const data = {
      search_keyword: ClearFilter ? "" : searchTerm,
      shareholder_search_keyword: ClearFilter ? "" : searchShareholder,
      user_id: userIdVal,
      clientuser_search_keyword: ClearFilter ? "" : searchClientUser,
    };
    try {
      const clients = await dispatch(
        fetchAllclients({
          datas:data,
          params: { sort: sortType, page: noOfPage, shareholder_id: shareholderId },
        })
      ).unwrap();
  
      setclientList(clients.data.sortedClients);
      setCurrentPage(clients.data.page);
      setTotalPage(clients.data.TotalPage);
      setTotalClient(clients.data.TotalClients);
    } catch (err) {
      console.log(err);
    }
  };
  
 
  useEffect(() => {
    // fetchClient(1)
    if (clearField == true || ClearFilter==true ) {
    
      fetchClient(1, userId);
      setclearField(false);
      setClearFilter(false);

    }
  }, [clearField == true,ClearFilter==true]);


  const ChangesearchTerm = async (e) => {
    setSearchTerm(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchTerm("");
    setclearField(true);
  };
  const handleClearShareholderSearch = () => {
    setSearchShareholder("");
    setclearField(true);
  }

  const handleShareholderSearchChange = async(e) => {
    setSearchShareholder(e.target.value);
  };

 

// In your Clients component
const handleExportExcel = async () => {
  try {
    let dataToExport = [];
    // If no search keyword or shareholder search term is present, fetch all clients.
    if (!searchTerm.trim() && !searchShareholder.trim()  && !searchClientUser.trim()) {
      const clientsResponse = await dispatch(
        fetchAllclients({
          datas: {
            search_keyword: "",
            shareholder_search_keyword: "",
            user_id: userId,
            exportData: true, // Request all records
          },
          params: {
            sort: sortType,
            // No pagination parameters needed here
          },
        })
      ).unwrap();
      dataToExport = clientsResponse.data.sortedClients;
    } else {
      // Otherwise, use the clientList already in state
      dataToExport = clientList;
    }
    
    // Transform the data into a simpler array for export.
    const worksheetData = dataToExport.map((client) => ({
      "ID": client.id,
      "Corporation Name": client.name,
      "Type of Business": client.businessStructure ? client.businessStructure.title : "",
      "# Partners": client.shareholder_count || 0,
      "Portal Admin": client.portalAdminUser
        ? `${client.portalAdminUser.name} ${client.portalAdminUser.surname}`
        : "Not assigned",
      "EIN": client.ein,
    }));

    // Use your export utility to generate and download the Excel file.
    exportToExcel(worksheetData, "clients.xlsx", "Clients");
  } catch (error) {
    console.error("Error exporting clients:", error);
  }
};


const handleClientUserSearchChange = (e) => {
  setSearchClientUser(e.target.value);
};

const handleClearClientUserSearch = () => {
  setSearchClientUser("");
  setclearField(true);
};

const handleClearAllFilters = () => {

  setSearchTerm("");
  setSearchShareholder("");
  setSearchClientUser("");
setClearFilter(true)
  //  fetchClient(1, userId);
};


const handleSearchClick = () => {
  // Perform the fetch with the current search states
  if (userId) {
    fetchClient(1, userId);
  }
};

  const toolbar = (
    <div className="d-flex align-items-center gap-2 mb-3">
{/* clear filter link */}

 {(searchTerm || searchShareholder || searchClientUser) && (
    <div>
      <a href="#!" onClick={handleClearAllFilters}>
        Clear Filters
      </a>
    </div>
  )}
      {/* First search input */}
      <div style={{ position: "relative" }}>
        <input
          autoComplete="off"
          type="text"
          id="search_clients"
          className="form-control form-control-sm"
          placeholder="Search by Name, EIN, City"
          aria-label="Search"
          aria-describedby="button-addon2"
          onChange={ChangesearchTerm}
          value={searchTerm}
          style={inputStyle}
        />
        {searchTerm && (
          <span style={iconStyle} onClick={handleClearSearch}>
            <i className="fas fa-times"></i>
          </span>
        )}
      </div>
  
      {/* Conditionally render the second search input if shareholderId is not present */}
      {!shareholderId && (
        <div style={{ position: "relative" }}>
          <input
            autoComplete="off"
            type="text"
            id="search_shareholder"
            className="form-control form-control-sm"
            placeholder="Search by Shareholder"
            aria-label="Search by Shareholder"
            aria-describedby="button-addon2"
            onChange={handleShareholderSearchChange}
            value={searchShareholder}
            style={inputStyle}
          />
          {searchShareholder && (
            <span style={iconStyle} onClick={handleClearShareholderSearch}>
              <i className="fas fa-times"></i>
            </span>
          )}
        </div>
      )}
  
      <div style={{ position: "relative" }}>
      <input
        autoComplete="off"
        type="text"
        id="search_clientuser"
        className="form-control form-control-sm"
        placeholder="Search by Assignee"
        aria-label="Search by Client User"
        onChange={handleClientUserSearchChange}
        value={searchClientUser}
        style={inputStyle}
      />
      {searchClientUser && (
        <span style={iconStyle} onClick={handleClearClientUserSearch}>
          <i className="fas fa-times"></i>
        </span>
      )}
      
    </div>

    <button className="btn btn-primary btn-sm" onClick={handleSearchClick}>
        Search
      </button>



      <div className="ms-auto">
        <button className="btn btn-success btn-sm" onClick={handleExportExcel}>
          Export to Excel
        </button>
      </div>
    </div>
  );
  
  useEffect(() => {
    const userId = getUserIdFromToken();

    setuserId(userId);

    fetchClient(1, userId);
  }, [sortType, userId]);

  const handlePageChange = (page) => {
    fetchClient(page, userId);
  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 10;
    const totalSets = Math.ceil(TotalPage / maxPagesToShow);
    const startPage = (currentSet - 1) * maxPagesToShow + 1;
    const endPage = Math.min(currentSet * maxPagesToShow, TotalPage);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${CurrentPage === i ? "active" : ""}`}
        >
          <a
            className="page-link"
            href="#!"
            onClick={() => handlePageChange(i)}
          >
            {i}
          </a>
        </li>
      );
    }




    return (
      <>
        {currentSet > 1 && (
          <li className="page-item">
            <a
              className="page-link"
              href="#!"
              onClick={() => setCurrentSet(currentSet - 1)}
            >
              &laquo;
            </a>
          </li>
        )}
        {pages}
        {currentSet < totalSets && (
          <li className="page-item">
            <a
              className="page-link"
              href="#!"
              onClick={() => setCurrentSet(currentSet + 1)}
            >
              &raquo;
            </a>
          </li>
        )}
      </>
    );
  };

  const handleArrowClick = (field, direction) => {
    setActiveArrow(`${field}${direction}`);
    if (field == "corporation" && direction == "Down") {
      setsortType("name");
    }
    if (field == "corporation" && direction == "Up") {
      setsortType("new");
    }
  };

  const arrowStyles = (field, direction) => ({
    color: activeArrow === `${field}${direction}` ? "#000" : "#aaa",
    cursor: "pointer",
  });

  const openPortalAdminModal = (client) => {
    setSelectedClient(client);
    setShowPortalAdminModal(true);
    fetchAdminUsers(client.id);
  };

  const closePortalAdminModal = () => {
    setShowPortalAdminModal(false);
    // setSelectedClient(null);
  };

  // Handle submit from the modal
  const handlePortalAdminSubmit = async (newAdminId) => {
 
    const data = {
      portal_admin: newAdminId,
    };
    await clientService.update_admin_portal(selectedClient.id, data);
    fetchClient(1, userId);
    // Here you can call your API to update the portal admin
    closePortalAdminModal();
  };



  const ShareholderPage=(client_id)=>{
    navigate(`/shareholder/${client_id}`)
  }


  useEffect(() => {
    if (shareholderId && clientList.length > 0) {
      // parseInt for safe comparison
      const idToMatch = parseInt(shareholderId, 10);

      // We'll try to find the first occurrence of a matching shareholder
      for (const c of clientList) {
        const found = c.shareholders.find((sh) => sh.id === idToMatch);
        if (found) {
          setShareholderName(found.name);
          break;
        }
      }
    }
  }, [shareholderId, clientList]);

  const layoutTitle =
  shareholderId && shareholderName ? (
    <>
      Entities of {shareholderName}
      <a
        href="#!"
        onClick={(e) => {
          e.preventDefault();
          // Navigate to /clients (removes ?shareholder_id=... from the URL)
          navigate("/clients");
          window.location.reload();
        }}
        style={{ marginLeft: "10px", fontSize: "0.9rem" }}
      >
        See all clients
      </a>
    </>
  ) : (
    "Clients"
  );




  const openShareholderModal = (client) => {
    if (client.shareholders) {
      setSelectedShareholders(client.shareholders);
    } else {
      setSelectedShareholders([]);
    }
    setShowShareholderModal(true);
  };
  const closeShareholderModal = () => {
    setShowShareholderModal(false);
  };



  const openAssigneeModal = (client) => {
    setSelectedAssignees(client);
    setShowAssigneeModal(true);
  };

 


  return (
    <Layout title={layoutTitle} toolbar={toolbar}>


      <div className="card client-view-table list-table">
        <div className="card-body">
          <div className="table-info mb-4 d-flex justify-content-between align-items-center">
            <small className="text-muted">
              Showing Records {(CurrentPage - 1) * 25 + 1} to{" "}
              {Math.min(CurrentPage * 25, TotalClient)} of {TotalClient}
            </small>

            <div className="table-info mb-3 d-flex justify-content-between align-items-center">
       
        <nav aria-label="Page navigation example">
          <ul className="pagination pagination-sm">
            {renderPagination()}
          </ul>
        </nav>
      </div>
          </div>
          <table
            className="table table-sm table-striped"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th className="sort no-search counter" width="2%">
                  #
                </th>
                <th width="20%" className="align-item-center">
                  <span>Corporation Name </span>
                  <span className="ms-2">
                    <i
                      className="bi bi-chevron-up"
                      style={arrowStyles("corporation", "Up")}
                      onClick={() => handleArrowClick("corporation", "Up")}
                    ></i>
                    <i
                      className="bi bi-chevron-down"
                      style={arrowStyles("corporation", "Down")}
                      onClick={() => handleArrowClick("corporation", "Down")}
                    ></i>
                  </span>
                </th>
                <th width="15%">Type of Business</th>
                <th width="15%"># Partners</th>
                <th width="15%">Portal Admin</th>
                <th width="8%">Assignees</th>


                <th width="10%">
                  <span>EIN</span>

                  <span className="ms-2">
                    <i
                      className="bi bi-chevron-up"
                      style={arrowStyles("ein", "Up")}
                      onClick={() => handleArrowClick("ein", "Up")}
                    ></i>
                    <i
                      className="bi bi-chevron-down"
                      style={arrowStyles("ein", "Down")}
                      onClick={() => handleArrowClick("ein", "Down")}
                    ></i>
                  </span>
                </th>
                <th width="25%" className="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {clientList.map((client, index) => (
                <tr key={client.id}>
                  <td>{(CurrentPage - 1) * 25 + index + 1}</td>
                  <td>{client.name}</td>
                  <td>{client.businessStructure ? client.businessStructure.title : ""}</td>
                  <td>
  <span>{client.shareholder_count ? client.shareholder_count : 0}</span>
  {client.shareholder_count > 0 && (
    <a
      href="#!"
      className="ms-2"
      onClick={() => openShareholderModal(client)}
      style={{ textDecoration: "underline" }}
    >
      view
    </a>
  )}
</td>
  <td>
  {client.portalAdminUser ? (
    <>
      {client.portalAdminUser.name} {client.portalAdminUser.surname}
    </>
  ) : (
    "Not assigned"
  )}
  <button
    className="btn btn-outline-primary btn-xxs ms-1"
    onClick={() => openPortalAdminModal(client)}
  >
    Set
  </button>
</td>

<td>
                    <a href="#!" onClick={() => openAssigneeModal(client)}>
                      View Assignees
                    </a>
                  </td>


       {/* Replace your “Assignees” column rendering with something like this */}


                  <td>{client.ein}</td>
                  <td className="text-end">
                    <button
                      data-client={client.id}
                      className="btn btn-outline-success btn-xxs me-2"
                      onClick={() =>
                        navigate(`/documents/${client.id}/Files&Folder`)
                      }
                    >
                      Files
                    </button>
                    {/* <button className="btn btn-outline-primary btn-xxs me-2">
                      Client Users
                    </button> */}
                    <a
                      className="btn btn-outline-primary btn-xxs me-2"
                      onClick={() => DetailsPage(client.id)}
                    >
                      Details
                    </a>
                    <button className="btn btn-primary btn-xxs"  onClick={() => TaskPage(client.id)}>
                      Add Tasks
                    </button>
{/* TaskPage */}
                    {/* <button className="btn btn-primary btn-xxs"  onClick={() => ShareholderPage(client.id)}>
                    
                    Shareholders
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <nav aria-label="Page navigation example">
            <ul className="pagination pagination-sm justify-content-end">
              {renderPagination()}
            </ul>
          </nav>
        </div>
      </div>
      <Modal show={showPortalAdminModal} onHide={closePortalAdminModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Assign Portal Admin</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <PortalAdminModalContent
            client={selectedClient}
            connectedUsers={adminUsers}
            onSubmit={handlePortalAdminSubmit}
            onClose={closePortalAdminModal}
            fetchClient={fetchClient}
          />
        </Modal.Body>
      </Modal>


      <Modal show={showShareholderModal} onHide={closeShareholderModal}>
        <Modal.Header closeButton>
          <Modal.Title>Shareholders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShareholderTable shareholders={selectedShareholders} />
        </Modal.Body>
      </Modal>

      <AssigneeModal
        show={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        clientId={selectedAssignees ? selectedAssignees.id : null}
        clientName={selectedAssignees ? selectedAssignees.name : ""}
        portalAdminId={selectedAssignees ? selectedAssignees.portal_admin : ""}
        

      />

    </Layout>
  );
};

export default Clients;
