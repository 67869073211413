 // src/utils/exportExcel.js

import * as XLSX from "xlsx";

/**
 * Exports an array of objects to an Excel file.
 *
 * @param {Object[]} data - The data to be exported. Each object represents a row.
 * @param {string} fileName - The name of the exported Excel file.
 * @param {string} sheetName - The name of the sheet in the Excel file.
 */
export function exportToExcel(data, fileName = "data.xlsx", sheetName = "Sheet1") {
  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Convert JSON data to a worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

  // Write the workbook to a file
  XLSX.writeFile(workbook, fileName);
}
