
import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button, Accordion, } from 'react-bootstrap';
import TaskDataTableComponent from './components/ClientDetails_Tasks';
import ChatsDataTableComponent from './components/ClientDetails_chats';
import Layout from "../../components/layout/Layout";
import ClientDetails_BusinessInfo from './components/ClientDetails_business';
import ClientDetail_DBA from './components/ClientDetails_DBA';
import ClientDetails_Banks from './components/ClientDetails_Banks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import clientService from '../../services/client.service';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchclientsById } from '../../feature/client/client';
import chatsService from '../../services/chats.service';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { getUserIdFromToken } from '../../utils/decodeToken';
import ClientDeatail_DBA from './components/ClientDetails_DBA';
import ClientDetails_Address from './components/ClientDetails_address';
import { ClientDetails_ShareHolder } from './components/ClientDetails_ShareHolder';
import ClientDetails_Account from './components/ClientDetails_Account';


const Clientdetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  // console.log(location.pathname)
  // const clients = useSelector((state) => state.client);
  // console.log(clients)
  const navigate = useNavigate()
  const [clientDetails, setclientDetails] = useState({})


 
  const [addresses, setAddresses] = useState([]);
  const [banks, setBanks] = useState([]);
  const [dba, setDba] = useState([]);
  const [shareholders, setShareholders] = useState([]);
  const [taxAccounts, setTaxAccounts] = useState([]);

  const [businessStructure, setBusinessStructure] = useState('');
  const [businessActivity, setBusinessActivity] = useState('');
  const [dateOfFormation, setDateOfFormation] = useState('');
  const [ein, setEin] = useState('');
  const [registeredAgentAddress, setRegisteredAgentAddress] = useState('');
  const [registeredAgentFullName, setregisteredAgentFullName] = useState('');

  const [name, setName] = useState('');


  const [Threads, setThreads] = useState([])
  const [searchKeyword, setsearchKeyword] = useState("")
  const [currentPage, setcurrentPage] = useState(1)
  const[userId,setuserId]=useState("")





  useEffect(()=>{
    const userId = getUserIdFromToken();
    setuserId(userId)

  },[])



  // const clientids = JSON.parse(clientIds);
  const toolbar = (
    <>
      <div className="input-group input-group-sm mb-3" style={{ width: 'auto' }}>
        <input autoComplete="off" type="text" id="search_clients" className="form-control" style={{ borderColor: '#1E468E' }} placeholder="Search by Name, EIN, City" aria-label="Search" aria-describedby="button-addon2" />
      </div>
    </>
  );
  useEffect(() => {
    const userIdFromToken = getUserIdFromToken();
    setuserId(userIdFromToken);
  }, []);

  // ---------------------------
  // 3) Fetch client details
  // ---------------------------
  const fetchClient = useCallback(async () => {
    try {
      const client = await dispatch(
        fetchclientsById({ id })
      ).unwrap();

      // Store the entire client object
      setclientDetails(client);

      console.log("client",client.clients[0])

      // Also set local states for each sub-key
      setAddresses(client.clients[0].Addresses || []);
      setBanks(client.clients[0].banks || []);
      setDba(client.clients[0].dba || []);
      setShareholders(client.clients[0].shareholders || []);
      setTaxAccounts(client.clients[0].tax_accounts || []);

      setBusinessStructure(client.clients[0].business_structure || '');
      setBusinessActivity(client.clients[0].business_activity || '');
      setDateOfFormation(client.clients[0].date_of_formation || '');
      setEin(client.clients[0].ein || '');
      setRegisteredAgentAddress(client.clients[0].registered_agent_details.address || '');
      setregisteredAgentFullName(client.clients[0].registered_agent_details.name || '')
      setName(client.clients[0].name || '');

      // You can store other fields similarly
      localStorage.setItem('Userfolder', client.clients[0].foldername);
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, id]);



  const fetchThreads = async () => {
    try {
      const Threads = await chatsService.getAllThreads(searchKeyword, currentPage, userId, id)


      setThreads(Threads.threads)
      console.log(Threads.threads)



    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {

    fetchClient()
    fetchThreads()

  }, [])
    
const handleChange_User=()=>{
  navigate(`${location.pathname}/Client_portal_user`)
}
const handleChange_file=()=>{
  navigate(`${location.pathname}/Client_portal_file`)
}



  const clientHeaderToolbar =  (
    
      <>
        <button className='btn btn-outline-primary btn-sm' style={{ marginRight: '10px' }} onClick={() => navigate(`/chats?clientId=${id}`, { state: { clientId: id } })}>Client Chats & Tasks</button>
        <button className='btn btn-outline-primary btn-sm' style={{ marginRight: '10px' }} onClick={handleChange_User}>Client Portal Users</button>
        <button className='btn btn-outline-primary btn-sm' onClick={handleChange_file}>Files</button>


      </>
    );
  

    const clientHeaderDetails = () => {
      return (
        <>
          <div className='ms-2 fw-semibold'>
            {registeredAgentAddress}
          </div>
          <div className="structure text-black-50">
            {businessStructure}
          </div>
        </>
      );
    };

  return (

    <Layout
      title={name}
      subtitle={clientHeaderDetails()}
      toolbar={clientHeaderToolbar}
    >
      <hr className='mt-0' />

      <div className="row mb-5 gap-5">
        {/* Left side */}
        <div className="client-details col w-50">
          {/* Pass the relevant props to each component */}
          <div className='mb-5'>
            <ClientDetails_BusinessInfo
              businessStructure={businessStructure}
              businessActivity={businessActivity}
              dateOfFormation={dateOfFormation}
              ein={ein}
              registeredAgentAddress={registeredAgentAddress}
              registeredAgentFullName={registeredAgentFullName}

            />
          </div>

          <div className='mb-5'>
            <ClientDeatail_DBA dba={dba} />
          </div>

          <div className='mb-5'>
            <ClientDetails_Banks banks={banks} />
          </div>

          {/* If you have these components, you can also pass them data similarly: */}
          
            <div className='mb-5'>
              <ClientDetails_Address addresses={addresses} />
            </div>
            
            <div className='mb-5'>
              <ClientDetails_ShareHolder shareholders={shareholders} />
            </div>

            <div className='mb-5'>
              <ClientDetails_Account taxAccounts={taxAccounts} />
            </div>
         
        </div>

        {/* Right side */}
        <div className="client_tasks col w-50">
          <TaskDataTableComponent />
          <ChatsDataTableComponent Threads={Threads} userId={userId} />
        </div>
      </div>
    </Layout>
  )
}

export default Clientdetails