import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatFiles from "./ChatFiles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Modal } from "react-bootstrap";
// import chatService from '../../services/chat.service';
import { expandIcon } from "../../utils/icons";
import chatsService from "../../services/chats.service";
import { setLoading } from "../../feature/loading/loading";
import { setMessage } from "../../feature/message/message";
import message from "../../utils/message";
import FileManagerService from "../../services/FileManager.service";
import PreviewFileModal from "../filemanager/components/Filemanager_PreviewFileModal";
import { validateFiles } from "../../utils/validation";
import Error from "../../components/Error";
import { downloadFile } from "../../utils/downloadFiles";
import MediaManager from "../../components/mediaManager";
import DeleteChatModal from "./DeleteChatModal";

const ThreadDetails = ({
  threadId,
  fetchThreads,
  subject,
  fetchThreadMessage,
  hasTasks,
  setShowTasks,
  showTasks,
  Chatmessage,
  client_id,
  handleDeleteClick,
}) => {
  const loadingmessage = useSelector((state) => state.message.message);
  const isLoading = useSelector((state) => state.loading.loading);
  const [newMessage, setNewMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [fileDetails, setFileDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [fileIds, setFileIds] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([]);
  const [fileuploadingError, setfileuploadingError] = useState(null);
  const [fileuploadError, setfileuploadError] = useState("");
  const downloadExtensions = ["docx", "xlsx", "pptx"];
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const chatContainerRef = useRef(null);
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);

  const handleMessageChange = async (content) => {
    const cleanedContent = content.replace(/<p><br><\/p>/g, "");
    setNewMessage(cleanedContent);
  };

  let fileDetailsArray = [];

  const saveFile = async (fileDetails) => {
    const data = {
      clientId: client_id,
      files: fileDetails,
    };

    try {
      var response = await FileManagerService.saveFile(data);
      // uploadFilehandleClose()
    } catch (err) {
      console.log(err);
    }

    return response;
  };

  const handleFileUpload = async (file, clientId) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("clientId", clientId);

    try {
      setUploading(true);

      const Filesdetails = await FileManagerService.uploadFile(formData);

      setFileDetails((prevDetails) => [
        ...prevDetails,
        {
          originalFileName: Filesdetails.originalFileName,
          uniqueFileName: Filesdetails.uniqueFileName,
        },
      ]);

      fileDetailsArray.push({
        originalFileName: Filesdetails.originalFileName,
        uniqueFileName: Filesdetails.uniqueFileName,
        visibility: 1,
      });

      setUploadIndex((prevIndex) => prevIndex + 1);
      setUploading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setfileuploadingError(error.response.data.errors[0].msg);
      setUploading(false);
    }
  };

  const sendMessage = async () => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(message.loading));

      // if (uploadIndex < files.length && !uploading && !fileuploadingError) {
      //   for (let i = 0; i < files.length; i++) {
      //     await handleFileUpload(files[i], client_id);
      //   }
      // }

      // let fileIds = [];

      // if (fileDetailsArray.length > 0) {
      //   var saveFiles = await saveFile(fileDetailsArray)

      //   fileIds = saveFiles.dbEntries.map((entry) => entry.id);

      // }

      const data = {
        thread_id: threadId,
        messageBody: newMessage,
        files: fileIds.length > 0 ? fileIds : null,
      };

      await chatsService.SendNewMessage(threadId, data);
      fetchThreadMessage(threadId);
      fetchThreads();
      setNewMessage("");
      setFileIds([]);
      setFiles([]);
      setSelectedFileNames([]);
      setfileuploadError("");
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [Chatmessage]);

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;

    // Validate the selected files
    const validationErrors = validateFiles(selectedFiles);

    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => {
        console.error(`Error with file "${error.fileName}": ${error.reason}`);
      });

      setfileuploadError(
        `Error: ${validationErrors
          .map((error) => `${error.fileName}: ${error.reason}`)
          .join(", ")}`
      );
    }

    // If no validation errors, proceed with adding the files to the state
    setFiles([...files, ...selectedFiles]);
  };
  const handleClose = () => setShow(false);

  const handleShow = async (fileId, fileName, fileExtension) => {
    try {
      const res = await FileManagerService.filePreview(fileId);

      if (res) {
        const blob = res.data;
        const blobUrl = URL.createObjectURL(blob);
        setFileBlob(blobUrl);
        setFileType(res.headers["content-type"]);
        if (downloadExtensions.includes(fileExtension) && blobUrl) {
          downloadFile(blobUrl, fileName);
        } else {
          setShow(true);
        }
      } else {
        console.error("Failed to fetch file");
      }
    } catch (err) {
      setFileBlob(null);
      setShow(true);
      console.log(err);
    }
  };

  const handleMediaSelect = (selectedFiles) => {
    // Extract IDs and names from the selected files
    const fileIds = selectedFiles.map((file) => file.id);
    const fileNames = selectedFiles.map((file) => file.file_name);

    setFileIds(fileIds); // Store only the IDs
    setSelectedFileNames(fileNames); // Store file names
  };

  const handleDeleteChat = () => {
    console.log("Chat deleted!");
    // Add your deletion logic here (e.g., API call)
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => setShowDeleteModal(false);

  return (
    <div className="thread">
      <div className="container-fluid">
        <div className="thread__header">
          <span className="thread__subject">{subject}</span>
          {hasTasks && (
            <a
              onClick={() => setShowTasks(!showTasks)}
              className={`toggle-tasks ${
                showTasks ? "tasks-expanded" : "tasks-collapsed"
              } `}
            >
              <img src={expandIcon} width={24} />
            </a>
          )}
        </div>
        <div className="thread__chats">
          <div className="thread__chats__chats" ref={chatContainerRef}>
            {/* {Chatmessage.map((msg) => (
          <div
            key={msg.id}
            className={`message ${msg.senderType == "admin"  ? 'by-user' : 'by-other'}`}
          >
            <div className="message__header">
              <span className="message__sender">{msg.senderType == "admin" ? 'You' :msg.senderName}</span>
              <span className="message__time">{new Date(msg.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
            </div>
            <div className="message__message" dangerouslySetInnerHTML={{ __html: msg.body }} />
          </div>
        ))} */}

            {Chatmessage.map((msg) => (
              <div
                key={msg.id}
                className={`message-container ${
                  msg.senderType === "admin" ? "by-user" : "by-other"
                }`}
              >
                <div className="message">
                  <div className="message__header">
                    <span className="message__sender">
                      {msg.senderType === "admin" ? "You" : msg.senderName}
                    </span>
                    <span className="message__time">
                      {new Date(msg.created_at).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  </div>
                  <div
                    className="message__message"
                    dangerouslySetInnerHTML={{ __html: msg.body }}
                  />

                  {/* Check if there are files and render them */}
                  {/* {msg.files && msg.files.length > 0 && (
                  <div className="message__files">
                    {msg.files.map((file) => (
                      <div key={file.id} className="message__file">
                        <a href="#" onClick={() => handleShow(file.disk_name)} >{file.file_name}</a>
                      </div>
                    ))}
                  </div>
                )} */}

                  {/* Render ChatFiles component */}
                  {msg.files && msg.files.length > 0 && (
                    <ChatFiles files={msg.files} handleShow={handleShow} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="thread__newmessage">
          <ReactQuill
            value={newMessage}
            onChange={handleMessageChange}
            placeholder="Type Message here..."
            theme="snow"
            modules={{
              toolbar: [
                [{ size: [] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
              ],
            }}
          />

          <div className="d-flex justify-content-between align-items-center w-100 mt-2">
            {/* Left: Delete this chat */}
            {/* <div>
      <span
        style={{
          cursor: 'pointer',
          color: '#ff0000',
        }}
        onClick={() => setShowDeleteModal(true)}
      >
        Delete this chat
      </span>
    </div> */}

            <span
              className="text-danger"
              onClick={() => setShowDeleteModal(true)}
            >
              Delete this chat
            </span>

            {/* Right: Send and Attach files */}
            <div className="d-flex align-items-center gap-2">
              {/* { selected files details} */}
              {selectedFileNames.length > 0 && (
                <div>
                  {/* <strong>Selected Filess:</strong> */}
                  <ul className="list-unstyled mt-1">
                    {selectedFileNames.map((name, index) => (
                      <li style={{ fontSize: "0.7rem" , fontStyle:"italic" }} key={index}>
                        {name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {/* MediaManager */}
              <div className="ml-3">
                <MediaManager
                  onSelect={handleMediaSelect}
                  client_id={client_id}
                />
                {/* {selectedFileNames.length > 0 && (
                  <div>
                    <strong>Selected Filess:</strong>
                    <ul className="list-unstyled mt-1">
                      {selectedFileNames.map((name, index) => (
                        <li key={index}>{name}</li>
                      ))}
                    </ul>
                  </div>
                )} */}
              </div>
              {/* Send button */}
              <Button
                variant="primary"
                className="btn btn-sm btn-primary ml-3"
                onClick={sendMessage}
                disabled={isLoading || !newMessage}
              >
                {isLoading ? loadingmessage : "Send"}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <PreviewFileModal
        show={show}
        handleClose={handleClose}
        fileUrl={fileBlob}
        fileType={fileType}
      />
      <DeleteChatModal
        show={showDeleteModal}
        handleClose={handleCloseModal}
        handleConfirm={handleDeleteChat}
        handleDeleteClick={handleDeleteClick}
        threadId={threadId}
        setShowDeleteModal={setShowDeleteModal}
      />
    </div>
  );
};

export default ThreadDetails;
