import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap"; // Import Bootstrap components

import { foldericon } from "../../../utils/icons";
import FolderService from "../../../services/folder.service";
import Pagination from "../../../components/Pagination";
import { getFileIcon } from "../../../utils/fileIcon";

const FilemanagerCreateFolder = ({setshowFolderEdit,id,FolderIdEdit,getFolder,folderDetails,FolderName}) => {

  // const [folderName, setFolderName] = useState(FileStatus.folderDetails.name);


  // const handleFolderNameChange = (event) => {
  //   setFolderName(event.target.value);
  // };

  const [checkedFiles, setCheckedFiles] = useState([]);

  // console.log(checkedFiles)

  const [uncheckedFiles, setUncheckedFiles] = useState([]);
  const [FileStatus, setFileStatus] = useState([])
  
  const [currentPage, setcurrentPage] = useState(1)
  const [currentSet, setCurrentSet] = useState(1);

  const [TotalPage, setTotalPage] = useState("")

  const [searchKeyword, setsearchKeyword] = useState("")


  useEffect(() => {
    if (FileStatus) {
      // Initialize checked files based on FileStatus and maintain previously selected files
      setCheckedFiles((prevCheckedFiles) => {
        const newLinkedFiles = FileStatus.linkedFileIds || [];
        // Merge the previous selected files and the new linked files
        const mergedCheckedFiles = [...new Set([...prevCheckedFiles, ...newLinkedFiles])];
        return mergedCheckedFiles;
      });
    }
  }, [FileStatus]);


  const handleCheckboxChange = (fileId) => {
    setCheckedFiles((prevCheckedFiles) => {
      if (prevCheckedFiles.includes(fileId)) {
        // If the file ID is already in the checked array, remove it (unchecking)
        setUncheckedFiles((prevUncheckedFiles) => [...prevUncheckedFiles, fileId]);
        return prevCheckedFiles.filter((id) => id !== fileId);
      } else {
        // If the file ID is not in the checked array, add it (checking)
        setUncheckedFiles((prevUncheckedFiles) =>
          prevUncheckedFiles.filter((id) => id !== fileId)
        );
        return [...prevCheckedFiles, fileId];
      }
    });
  };


const EditFile=async()=>{

  const data={
    file_ids:checkedFiles
  }
  try{
await FolderService.EditFileToFolder(FileStatus.folderDetails.id,data)
getFolder()
setshowFolderEdit(false)
folderDetails(FolderIdEdit,FolderName)

}catch(err){
    console.log(err)
  }
}
const handlesearchFile=(e)=>{
  setsearchKeyword(e.target.value)
}



const AllFiles = async () => {
  const data = {
    clientId:id,
    limit:10,
    page:currentPage,
    search: searchKeyword,
  }
  try {
    const file = await FolderService.EditFolder(FolderIdEdit, data)

    setFileStatus(file.data)
    setcurrentPage(file.data.pagination.currentPage)
    setTotalPage(file.data.pagination.totalPages)
  } catch (err) {
    console.log(err)
  }
}

useEffect(()=>{
  AllFiles()
},[currentPage,searchKeyword])

  return (
    <div className="editfolder-container">
      <div className="main-search">
        <div className="form-label">Folder Name</div>
        <input
          type="text"
          className="form-control form-control-md"
          placeholder="Folder Name"
          aria-label="Folder Name"
          aria-describedby="addon-wrapping"
          value={FileStatus.folderDetails&&FileStatus.folderDetails.name}
          disabled
       
        />
      </div>
      <div className="card">
        <div className="card-body">
          <div className="header">
            <p>Update File(s) in folder</p>
            <div className="header-input">
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Search..."
                aria-label="search"
                aria-describedby="addon-wrapping"
                onChange={handlesearchFile}
                
              />
            </div>
          </div>
          <table className="list-table table table-hover table-sm">
            <thead>
              <tr>
                <th>File(s)</th>
                <th className="upload">Uploaded on</th>
              </tr>
            </thead>
            <tbody>
              {FileStatus.filesStatus&&FileStatus.filesStatus.map((file, index) => (
                <tr key={index}>
                  <td>
                    
                  <div className="d-flex align-items-center">
                    <input
                        className="form-check-input border-secondary me-2"
                        type="checkbox"
                        checked={checkedFiles&&checkedFiles.includes(file.id)}
                        onChange={() => handleCheckboxChange(file.id)}
                      />
                  

                    <div className="file-name d-flex gap-2 align-items-center">
                        <div role="button"><img style={{ width: "16px" }} src={getFileIcon(file.name)} alt={`${file.name} Icon`} className="file-icon" /></div>
                        <span>{file.name}</span>
                      </div>
                      </div>
                  </td>
                  <td className="upload">{file.uploaded_on}</td>
                </tr>
              ))}
            </tbody>
            
          </table>
          <div className="mt-2 float-end">
          <Pagination
           currentPage={currentPage}
            TotalPage={TotalPage}
            currentSet={currentSet}
            setCurrentSet={setCurrentSet}
            setcurrentPage={setcurrentPage}
          />
          </div>
        </div>
      </div>
      <div className="action-buttons justify-content-between">
       
        <Button variant="outline-secondary" className="cancel-btn"   onClick={()=>setshowFolderEdit(false)}>
          Cancel
        </Button>

        <Button variant="primary" className="save-btn" onClick={EditFile}>
          Save Folder
        </Button>
      </div>
    </div>
  );
};

export default FilemanagerCreateFolder;
