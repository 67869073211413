import React, { useEffect, useState } from "react";
import { documenticon } from "../utils/icons";
import { useNavigate } from "react-router-dom";
import FileManagerService from "../services/FileManager.service";
import { downloadFile } from "../utils/downloadFiles";
import PreviewFileModal from "../pages/filemanager/components/Filemanager_PreviewFileModal";
import { setLoading } from "../feature/loading/loading";
import { setMessage } from "../feature/message/message";
import { useDispatch } from "react-redux";

const NotificationItem = ({ title, timeAgo, senderName, message, model_id, model }) => {
  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [show, setShow] = useState(false);
  const downloadExtensions = ["docx", "xlsx", "pptx"];

  const handleClose = (e) => {
    if(e) e.stopPropagation(); // Prevent bubbling if needed
    setShow(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNotificationDetails = async (e) => {
    if(e) e.stopPropagation(); // Prevent event bubbling
    // If modal is already open, do nothing
    if (show) return;

    // Determine which model to use
    if (model === "ChatThread") {
      const queryParams = new URLSearchParams({
        threadId: model_id,
      });
      navigate(`/chats?${queryParams.toString()}`, {
        state: { NotificationThreadId: model_id },
      });
    } else if (model === "ClientFile") {
      try {
        dispatch(setLoading(true));
        dispatch(setMessage("Please wait.."));
        const res = await FileManagerService.filePreview(model_id);
        if (res) {
          const blob = res.data;
          const blobUrl = URL.createObjectURL(blob);
          setFileBlob(blobUrl);
          setFileType(res.headers["content-type"]);
          setShow(true);
          dispatch(setLoading(false));
        } else {
          console.error("Failed to fetch file");
        }
      } catch (err) {
        setFileBlob(null);
        setShow(true);
        dispatch(setLoading(false));
        console.log(err);
      }
    } else {
      navigate("/");
    }
  };

  return (
    <div className={`notification-item ${title}`} onClick={handleNotificationDetails}>
      <div className='notification-title'>{title}</div>
      <div className="notification-content">
        <div className="message" dangerouslySetInnerHTML={{ __html: message }} />
        <p className="notification-time">By {senderName} | {timeAgo}</p>
      </div>
      {show && (
        <PreviewFileModal
          show={show}
          handleClose={handleClose}
          fileUrl={fileBlob}
          fileType={fileType}
        />
      )}
    </div>
  );
};

const Notifications = ({ notifications }) => (
  <div className="dropdown-menu dropdown-menu-end notifications-dropdown" data-bs-auto-close="outside" aria-labelledby="notificationsDropdown">
    <div className="card-body">
      <ul className="list-group list-group-flush">
        {notifications.map((notification, index) => (
          <li key={index} className="list-group-item">
            <NotificationItem key={index} {...notification} />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Notifications;
