import React, { useState } from "react";
import PreviewFileModal from "./Filemanager_PreviewFileModal"; 
import { getFileIcon } from "../../../utils/fileIcon";
import { foldericon, trashicon } from "../../../utils/icons";
import FileManagerService from "../../../services/FileManager.service";
import { formatDateTime } from "../../../utils/dateTimeFormat";
import { setLoading } from "../../../feature/loading/loading";
import { setMessage } from "../../../feature/message/message";
import message from "../../../utils/message";
import { useDispatch } from "react-redux";
import Pagination from "../../../components/Pagination";
import { handleVisibilityChange } from "../../../utils/visible";
import { downloadFile } from "../../../utils/downloadFiles";
import { Modal } from "react-bootstrap";

const Files = ({files, getFiles, trashedFile,handleShowAddFile , currentPage, TotalPage, currentSet, setCurrentSet, setcurrentPage,setSortDirection,setSortBy,sortBy,sortDirection }) => {

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  
 

  const downloadExtensions = ['docx', 'xlsx', 'pptx'];

  const handleClose = () => setShow(false);
  
  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);


//   const handleShow = (fileUrl, fileName,fileExtension) => {
 
//  if (downloadExtensions.includes(fileExtension)) {
 
//       downloadFile(fileUrl, fileName);
//     } else {
   
//       setShow(true);
//       setSelectedFileUrl(fileUrl);
//     }
//   };

const handleShow=async(fileId,fileUrl, fileName,fileExtension)=>{
try{
const res= await FileManagerService.filePreview(fileId)
// console.log(res)
  
    if (res) {
    
    const blob = res.data;
    const blobUrl=URL.createObjectURL(blob)
    setFileBlob(blobUrl); 
    setFileType(res.headers['content-type'])
    if (downloadExtensions.includes(fileExtension) &&blobUrl ){
     downloadFile(blobUrl, fileName);
     }else{
    setShow(true);
  }
  } else {
    console.error('Failed to fetch file');
  }
}catch(err){
  setFileBlob(null)
  setShow(true);
  console.log(err)

}




}
  
  const handleDelete = async (id) => {
    try {
      
    dispatch(setLoading(true));
    dispatch(setMessage(message.loading));
      await FileManagerService.DeleteFile(id);
      getFiles();
      trashedFile();
      dispatch(setMessage(message.fileDeleted));
    } catch (err) {
      console.log(err);
      
      dispatch(setMessage(err.response.data.error));
    }finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };




  const ucfirst = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";




  const handleOpenFileAdd=(id)=>{
    
    handleShowAddFile(id)
  }

  const handleSort = (column) => {
    const newDirection = sortBy === column && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortDirection(newDirection);
    
  };

  return (
    <>
      <div className="list-table table-responsive">
        <table className="table table-hover table-sm mt-4">
          <thead>

          
            {/* <tr>
              <th width="40%">Name</th>
              <th width="15%">Uploaded By</th>
              <th width="15%">Uploaded on</th>
              <th width="10%">Status</th>
              <th width="7%" className="text-center">Visible</th>
              <th width="7%" className="text-center">Add to Folder</th>
              <th width="7%" className="text-center">Delete</th>
            </tr> */}


            <tr>
              <th width="40%" onClick={() => handleSort("file_name")}>
                Name {sortBy === "file_name" && (sortDirection === "ASC" ? "↑" : "↓")}
              </th>
              <th width="15%" onClick={() => handleSort("uploaded_by")}>
                Uploaded By {sortBy === "uploaded_by" && (sortDirection === "ASC" ? "↑" : "↓")}
              </th>
              <th width="15%" onClick={() => handleSort("created_at")}>
                Uploaded on {sortBy === "created_at" && (sortDirection === "ASC" ? "↑" : "↓")}
              </th>
              <th width="10%" onClick={() => handleSort("approval_status")}>
                Status {sortBy === "approval_status" && (sortDirection === "ASC" ? "↑" : "↓")}
              </th>
              <th width="7%" className="text-center">Visible</th>
              <th width="7%" className="text-center">Add to Folder</th>
              <th width="7%" className="text-center">Delete</th>
            </tr>

          </thead>
          <tbody>
            {files.length > 0 ? files.map((file, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex gap-1 align-items-center">
                    <div role="button">
                      <img src={getFileIcon(file.file_name)} alt={`${file.file_name} Icon`} className="file-icon" />
                    </div>
                    <span role="button" onClick={() => handleShow(file.id,file.file_url,file.file_name,file.file_type)}>{file.file_name}</span>
                  </div>
                </td>
                <td>{file.uploaded_by}</td>
                <td>{file.uploaded_on}</td>
                <td>
                  <span
                    className={`badge rounded-pill ${file.status.toLowerCase() === 'approved' ? 'bg-success' : 'bg-secondary'}`}
                  >
                    {ucfirst(file.status)}
                  </span>
                </td>
                <td className="text-center">
                  <div className="form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={file.visible ==true}
                      onChange={() => handleVisibilityChange(file.id, file.visible,getFiles)}
                    />
                  </div>
                </td>
                <td align="center">
                  <img src={foldericon} style={{ width: "20px" }} alt="folder icon" role="button" title="Move file to folder" onClick={()=>handleOpenFileAdd(file.id)} />
                </td>
                <td align="center">
                  <img
                    role="button"
                    style={{ width: "18px" }}
                    title="Delete this file"
                    src={trashicon}
                    alt="trash icon"
                    onClick={() => handleDelete(file.id)}
                  />
                </td>
        
              </tr>


              
            )) : (
              <tr>
                <td colSpan="7">Data not found</td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
           currentPage={currentPage}
            TotalPage={TotalPage}
            currentSet={currentSet}
            setCurrentSet={setCurrentSet}
            setcurrentPage={setcurrentPage}
          />
      </div>

      {/* Modal Component */}
     {show&& <PreviewFileModal show={show} handleClose={handleClose} fileUrl={fileBlob}  fileType={fileType}/>}


    </>
  );
};

export default Files;
