// import React, { useState, useEffect } from 'react';
// import UserService from '../../../services/user.service';
// import { useSearchParams } from 'react-router-dom';
// import clientService from '../../../services/client.service';
// import { getUserIdFromToken } from '../../../utils/decodeToken';

// const PortalAdminModalContent = ({ client, onClose,fetchClient }) => {
//   const [selectedAdmin, setSelectedAdmin] = useState('');
//  console.log("selectedAdminHello_...",selectedAdmin)
//   const [connectedUsers, setConnectedUsers] = useState([]); // Store connected admin users
//   const [searchParams] = useSearchParams();

//   console.log("connectedUsers",connectedUsers)
//   // Get URL Parameters
//   const clientId = searchParams.get("client_id");
//   const clientName = searchParams.get("client_name");
//   const portalAdmin = searchParams.get("portal_admin");
//   const isInsideIframe = window.self !== window.top;
//   // Ensure we have the correct client data
//   const clientData = client || { id: clientId, name: clientName, portal_admin: portalAdmin };

//   // Function to fetch connected admin users for a given client
//   const fetchAdminUsers = async () => {
//     try {
//       const response = await UserService.getAdminUserDetails(clientData.id);

//       console.log("response", response);
  
//       if (response && Array.isArray(response.users)) {
//         setConnectedUsers(response.users);

//         // Only set selected admin if it hasn't been set by user
//         if (!selectedAdmin) {
//           setSelectedAdmin(clientData.portal_admin || response.users[0]?.id || '');
//         }
//       } else {
//         setConnectedUsers([]);
//       }
//     } catch (error) {
//       console.error("Error fetching admin users:", error);
//       setConnectedUsers([]);
//     }
//   };

//   useEffect(() => {
//     fetchAdminUsers();
//   }, []); // Run only when the component mounts

//   useEffect(() => {
//     // Preselect the portal admin only if it hasn’t been set manually
//     if (!selectedAdmin && clientData.portal_admin) {
//       setSelectedAdmin(clientData.portal_admin);
//     }
//   }, [clientData.portal_admin, connectedUsers]);

//   const handleChange = (e) => {
//     setSelectedAdmin(e.target.value); // Store user-selected admin
//   };


//   const handlePortalAdminSubmit = async (newAdminId) => {
//  console.log("selectedAdmin",selectedAdmin)
//     const data = {
//       portal_admin: newAdminId,
//     };
//     await clientService.update_admin_portal(clientData.id, data);
//     const userId = getUserIdFromToken();
//     if(!clientId){

//       fetchClient(1, userId);
//       onClose()
//     }
//     window.parent.postMessage("closeModal", "*");
//   };


//   const handleSubmit = (e) => {
//     e.preventDefault();
//     handlePortalAdminSubmit(selectedAdmin);
//   };
//   useEffect(() => {
//     const receiveMessage = (event) => {
//       if (event.data === "submitPortalAdmin") {
//         // Use functional update to get the latest selectedAdmin state
//         setSelectedAdmin((prevAdmin) => {
//           handlePortalAdminSubmit(prevAdmin);
//           return prevAdmin; // Ensure state does not reset
//         });
//       }
//     };

//     window.addEventListener("message", receiveMessage);
//     return () => window.removeEventListener("message", receiveMessage);
//   }, []);

//   return (
//     <div>
//      <h5>Set Portal Admin</h5>
//      <small className="text-muted d-block mb-3">{clientData.name}</small>
//     <form>
//       <div className="mb-3">
//         {/* <label htmlFor="portalAdminSelect" className="form-label">
//           Select Portal Admin
//         </label> */}
//         <select
//           className="form-select"
//           id="portalAdminSelect"
//           value={selectedAdmin}
//           onChange={handleChange}
//         >
//           {connectedUsers.map((user) => {
//     const fullName = user.surname
//       ? `${user.name} ${user.surname}`
//       : user.name; // fallback if surname is empty
//     return (
//       <option key={user.id} value={user.id}>
//         {fullName}
//       </option>
//     );
//   })}
//         </select>
//       </div>

//       {!isInsideIframe && (
//           <>
//             <button type="button" className="btn btn-primary" onClick={handleSubmit}>
//               Submit
//             </button>
//             <button type="button" className="btn btn-secondary ms-2" onClick={onClose}>
//               Close
//             </button>
//           </>
//         )}
//     </form>
//   </div>
//   );
// };

// export default PortalAdminModalContent;



import React, { useState, useEffect } from "react";
import UserService from "../../../services/user.service";
import { useSearchParams } from "react-router-dom";
import clientService from "../../../services/client.service";
import { getUserIdFromToken } from "../../../utils/decodeToken";

const PortalAdminModalContent = ({ client, onClose, fetchClient }) => {
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [searchParams] = useSearchParams();

  // Get URL Parameters
  const clientId = searchParams.get("client_id");
  const clientName = searchParams.get("client_name");
  const portalAdmin = searchParams.get("portal_admin");
  const isInsideIframe = window.self !== window.top;

  // Ensure we have the correct client data
  const clientData =
    client || { id: clientId, name: clientName, portal_admin: portalAdmin };

  // Fetch connected admin users
  const fetchAdminUsers = async () => {
    try {
      const response = await UserService.getAdminUserDetails(clientData.id);

      if (response && Array.isArray(response.users)) {
        setConnectedUsers(response.users);

        // Only set the selected admin if there's already a portal admin
        // and user hasn't picked one yet
        if (!selectedAdmin && clientData.portal_admin) {
          setSelectedAdmin(clientData.portal_admin);
        }
      } else {
        setConnectedUsers([]);
      }
    } catch (error) {
      console.error("Error fetching admin users:", error);
      setConnectedUsers([]);
    }
  };

  useEffect(() => {
    fetchAdminUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If portal_admin is present and user hasn't chosen anything, preselect it
    if (!selectedAdmin && clientData.portal_admin) {
      setSelectedAdmin(clientData.portal_admin);
    }
  }, [clientData.portal_admin, selectedAdmin]);

  const handleChange = (e) => {
    setSelectedAdmin(e.target.value);
  };

  const handlePortalAdminSubmit = async (newAdminId) => {
    const data = {
      portal_admin: newAdminId,
    };
    await clientService.update_admin_portal(clientData.id, data);

    const userId = getUserIdFromToken();
    // If we are not inside an iFrame, refresh the client list in the parent
    if (!clientId) {
      fetchClient(1, userId);
      onClose();
    }
    // If we are inside an iFrame, post a message to close
    window.parent.postMessage("closeModal", "*");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handlePortalAdminSubmit(selectedAdmin);
  };

  useEffect(() => {
    const receiveMessage = (event) => {
      if (event.data === "submitPortalAdmin") {
        // Use functional update to get the latest selectedAdmin state
        setSelectedAdmin((prevAdmin) => {
          handlePortalAdminSubmit(prevAdmin);
          return prevAdmin;
        });
      }
    };

    window.addEventListener("message", receiveMessage);
    return () => window.removeEventListener("message", receiveMessage);
  }, []);

  return (
    <div>
      <h5>Set Portal Admin</h5>
      <small className="text-muted d-block mb-3">{clientData.name}</small>
      <form>
        <div className="mb-3">
          <select
            className="form-select"
            id="portalAdminSelect"
            value={selectedAdmin}
            onChange={handleChange}
          >
            {/* Placeholder option */}
            <option value="">Choose one</option>

            {/* Mapped user options */}
            {connectedUsers.map((user) => {
              const fullName = user.surname
                ? `${user.name} ${user.surname}`
                : user.name;
              return (
                <option key={user.id} value={user.id}>
                  {fullName}
                </option>
              );
            })}
          </select>
        </div>

        {!isInsideIframe && (
          <>
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
            <button type="button" className="btn btn-secondary ms-2" onClick={onClose}>
              Close
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default PortalAdminModalContent;
