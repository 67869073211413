import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from "../../components/layout/Layout";

import clientService from '../../services/client.service';

import '@fortawesome/fontawesome-free/css/all.min.css';
import { useDispatch, useSelector } from 'react-redux';
import client, { fetchAllclients } from '../../feature/client/client';

import { getUserIdFromToken } from '../../utils/decodeToken';




const inputStyle = {
  borderColor: '#1E468E',
  position: 'relative',
  width: '100%'
};

const iconStyle = {
  position: 'absolute',
  color: '#1E468E',
  fontSize: '19px',
  right: '1px',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: '10',

};








const Clients = () => {
  const dispatch = useDispatch();


  const navigate = useNavigate();

  const DetailsPage = (id) => {

    navigate(`/client/${id}`);;

  }
  const [searchTerm, setSearchTerm] = useState('');

  const ChangesearchTerm = async (e) => {
    setSearchTerm(e.target.value)
  }
  const [clientList, setclientList] = useState([])
  const [CurrentPage, setCurrentPage] = useState("")
  const [TotalPage, setTotalPage] = useState("")
  const [TotalClient, setTotalClient] = useState("")
  const [currentSet, setCurrentSet] = useState(1);
  const [clearField, setclearField] = useState(false)
  const [activeArrow, setActiveArrow] = useState('');
  const [sortType, setsortType] = useState("new")
const[userId,setuserId]=useState("")




  const fetchClient = useCallback(async (noOfPage,userId) => {
    try {

      const clients = await dispatch(
        fetchAllclients({
          datas: { search_keyword: searchTerm,user_id:userId },
          params: { sort: sortType, page: noOfPage }
        })
      ).unwrap();
      // console.log(clients)


     
      setclientList(clients.data.sortedClients);
      setCurrentPage(clients.data.page);
      setTotalPage(clients.data.TotalPage);
      setTotalClient(clients.data.TotalClients);
    } catch (err) {
      console.log(err);
    }
  }, [searchTerm, sortType]);


  const clients = useSelector((state) => state.client);
  console.log(clients)
  useEffect(() => {
    // fetchClient(1)
    if (clearField == true) {

      fetchClient(2,userId)
      setclearField(false)
    }
  }, [clearField == true])

  const handleClearSearch = () => {
    setSearchTerm('');
    setclearField(true)
  };

  const toolbar = (

    <>
      <div className='col-auto' style={{ position: 'relative' }}>

        <input autoComplete="off" type="text" id="search_clients" className="form-control form-control-sm" placeholder="Search by Name, EIN, City" aria-label="Search" aria-describedby="button-addon2" onChange={ChangesearchTerm} value={searchTerm} style={inputStyle}
        />

        {searchTerm && <span style={iconStyle} onClick={handleClearSearch}>
          <i className="fas fa-times" ></i>
        </span>}
      </div>
    </>
  );




  useEffect(() => {
    const userId = getUserIdFromToken();
 
    setuserId(userId)

    fetchClient(1,userId)

  }, [sortType, searchTerm,userId])


  const handlePageChange = (page) => {
    fetchClient(page,userId);

  };

  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 3;
    const totalSets = Math.ceil(TotalPage / maxPagesToShow);
    const startPage = (currentSet - 1) * maxPagesToShow + 1;
    const endPage = Math.min(currentSet * maxPagesToShow, TotalPage);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${CurrentPage === i ? 'active' : ''}`}>

          <a className="page-link" href="#!" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }

    return (
      <>
        {currentSet > 1 && (
          <li className="page-item">
            <a className="page-link" href="#!" onClick={() => setCurrentSet(currentSet - 1)}>
              &laquo;
            </a>
          </li>
        )}
        {pages}
        {currentSet < totalSets && (
          <li className="page-item">
            <a className="page-link" href="#!" onClick={() => setCurrentSet(currentSet + 1)}>
              &raquo;
            </a>
          </li>
        )}
      </>
    );
  };

  const handleArrowClick = (field, direction) => {
    setActiveArrow(`${field}${direction}`);
    if (field == "corporation" && direction == "Down") {
      setsortType("name")

    }
    if (field == "corporation" && direction == "Up") {

      setsortType("new")
    }
  };

  const arrowStyles = (field, direction) => ({
    color: activeArrow === `${field}${direction}` ? '#000' : '#aaa',
    cursor: 'pointer'
  });
  return (

    <Layout title="Clients" toolbar={toolbar}>
      <div className="card client-view-table list-table">
        <div className="card-body">
          <div className="table-info mb-4 d-flex justify-content-between align-items-center">
            <small className="text-muted">Showing Records {(CurrentPage - 1) * 25 + 1} to {Math.min(CurrentPage * 25, TotalClient)} of {TotalClient}</small>
          </div>
          <table className="table table-sm table-striped" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className="sort no-search counter" width="2%">#</th>
                <th width="25%" className='align-item-center'>
                  <span>Corporation Name </span>
                  <span className='ms-2'>
                    <i
                      className="bi bi-chevron-up"
                      style={arrowStyles('corporation', 'Up')}
                      onClick={() => handleArrowClick('corporation', 'Up')}
                    ></i>
                    <i
                      className="bi bi-chevron-down"
                      style={arrowStyles('corporation', 'Down')}
                      onClick={() => handleArrowClick('corporation', 'Down')}
                    ></i>
                  </span>
                </th>
                <th width="22%">Type of Business</th>
                <th width="15%"># Partners</th>
                <th width="11%">
                  <span>EIN</span>
                  <span className='ms-2'>
                    <i
                      className="bi bi-chevron-up"
                      style={arrowStyles('ein', 'Up')}
                      onClick={() => handleArrowClick('ein', 'Up')}
                    ></i>
                    <i
                      className="bi bi-chevron-down"
                      style={arrowStyles('ein', 'Down')}
                      onClick={() => handleArrowClick('ein', 'Down')}
                    ></i>
                  </span>
                </th>
                <th width="20%" className="text-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {clientList.map((client, index) => (
                <tr key={client.id}>
                  <td>{(CurrentPage - 1) * 25 + index + 1}</td>
                  <td>{client.name}</td>
                  <td>{client.type}</td>
                  <td>{client.no_of_partners}</td>
                  <td>{client.ein}</td>
                  <td className="text-end">
                  <button data-client={client.id} className="btn btn-outline-success btn-xxs me-2"  onClick={() => navigate(`/documents/${client.id}/Files`)}  >Files</button>
                  <button className="btn btn-outline-primary btn-xxs me-2" >Client Users</button>
                    <a className="btn btn-outline-primary btn-xxs me-2" onClick={() => DetailsPage(client.id)}  >Details</a>
                    <button  className="btn btn-primary btn-xxs"  >Add Tasks</button>
                    

                  </td>
                </tr>
              ))}
            </tbody>

          </table>
          
            <nav aria-label="Page navigation example">
              <ul className="pagination pagination-sm justify-content-end">
                {renderPagination()}
              </ul>
            </nav>
          

        </div>
      </div>


    </Layout>


  );
}

export default Clients