import React from 'react';
import attachmentIcon from '../../assets/images/attachment_icon.svg'

import { unreadIcon } from '../../utils/icons'; 




const ChatList = ({ threads, handleRowClick,selectedThreadId, handleDeleteClick }) => {
  console.log("threads",threads)
return (
    <>
      {/* <pre>{JSON.stringify(threads, null, 2)}</pre> */}

    <ul className="chats-list" style={{ cursor: 'pointer' }}>
      {threads.map((thread) => (
        <li
          key={thread.id}
          className={`chats-list__item 
                      ${thread.unread ? "unread" : "read"}
                      ${thread.id == selectedThreadId ? "active": ""}
                      `
                    }
          onClick={() => handleRowClick(thread)}
        >
          <div className='container-fluid'>
              <div className="unread-icon">
                <img src={unreadIcon} />
              </div>

            <div className='chats-list__left'>
            <div className='chats-list__sender_client'>
       
                        <div className='sender-name'>
                                {thread.lastMessageSender} <span className='text-muted fw-normal ms-2' style={{fontSize:'0.8rem'}}>{thread.lastMessageSenderCompany}</span>
                            </div>

                <span className='sender-company'>{thread.client.name} </span>
 
              </div>
              <div className="chats-list__message">
                <span className='chats-list__subject'>{thread.subject}</span>
                <span className='chats-list__summary' dangerouslySetInnerHTML={{ __html: thread.lastMessage }} />
              </div>
              
            </div>

            {thread.hasAttachments && (
              <div className='chats-list__attachment'>
                <img src={attachmentIcon} className='attachment-icon' />
              </div>
            )}


            <div className='chats-list__date'>
              {thread.lastMessageTime}
            </div>

            

          </div>
        </li>
      ))
      }
    </ul >
    </>
  );
};

export default ChatList;
